import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (process.env.WEBPACK_MODE === 'production') {
    Sentry.init({
        dsn: 'https://3e894669c7ad45a698088ca119bcce16@o4504374512386048.ingest.sentry.io/4504374514810880',
        integrations: [new BrowserTracing()],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
