import {Button} from 'antd';
import React, {useState} from 'react';
import styled from 'styled-components';
import {CycleSelectForm} from './CycleSelectForm';

const CycleSelectWrapper = styled.div`
    text-align: center;
`;

export const CycleSelect = () => {
    const [visible, setVisible] = useState(false);

    const showModal = () => {
        setVisible(true);
    };

    return (
        <CycleSelectWrapper>
            <Button type="primary" onClick={showModal}>
                Добавить к сравнению
            </Button>
            <CycleSelectForm visible={visible} closeModal={() => setVisible(false)} />
        </CycleSelectWrapper>
    );
};
