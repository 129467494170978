import React, {useEffect, useState} from 'react';
import {Button, Modal, Pagination, Table, Typography} from 'antd';

import {useRouteMatch, withRouter} from 'react-router';
import isEmpty from 'lodash/isEmpty';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {createFarm, getFarmsList, updateFarm} from '../../../reduxStore/modules/admin';
import {ActionLink, HeaderPart, ListWrapper} from '../Styled';
import {CreateFarmForm} from '../../../components/Forms/CreateFarmForm';

const {Title} = Typography;

const FarmgroupListRaw = props => {
    const [visibleModal, setVisability] = useState(false);
    const [editable, setEditable] = useState({});
    const {url} = useRouteMatch();

    const toogleModalState = () => {
        if (visibleModal) {
            setEditable({});
        }
        setVisability(!visibleModal);
    };

    const closeModal = () => {
        setEditable({});
        setVisability(false);
    };

    const onEditClick = (e, record) => {
        e.stopPropagation();
        setEditable(record);
        toogleModalState();
    };

    const columns = [
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Порядок',
            dataIndex: 'sortOrder',
            key: 'sortOrder',
        },
        {
            title: 'Длительность цикла',
            dataIndex: 'cycleLength',
            key: 'cycleLength',
        },
        {
            title: 'Действия',
            key: 'operation',
            fixed: 'right',
            width: 200,
            render: (text, record) => <ActionLink onClick={e => onEditClick(e, record)}>Изменить</ActionLink>,
        },
    ];

    const {farms, actions} = props;

    const fetchFarmsList = () => {
        actions.getFarmsList({id: props.match.params.farmgroupId});
    };

    useEffect(() => {
        fetchFarmsList();
    }, []);

    const onRowClick = obj => {
        const check = url.slice(-1);
        let origin = url;

        if (check === '/') {
            origin = origin.substr(0, origin.length - 1); // @TODO use strict in routing
        }

        props.history.push(`${origin}/${obj.id}`);
    };

    const onSubmitForm = values => {
        if (isEmpty(editable)) {
            actions
                .createFarm(values)
                .then(() => {
                    closeModal();
                    fetchFarmsList();
                })
                .catch(err => {
                });
        } else {
            actions
                .updateFarm({id: editable.id, data: values})
                .then(() => {
                    closeModal();
                    fetchFarmsList();
                })
                .catch(err => {
                });
        }
    };

    return (
        <ListWrapper>
            <HeaderPart>
                <Title>Корпуса</Title>
                <Button type="primary" onClick={toogleModalState}>
                    Создать новый
                </Button>
                <Modal
                    visible={visibleModal}
                    title="Создание корпуса"
                    footer={null}
                    onCancel={closeModal}
                    destroyOnClose
                >
                    <CreateFarmForm
                        onSubmit={onSubmitForm}
                        initialValues={{
                            ...editable,
                            parentId: props.match.params.farmgroupId,
                        }}
                    />
                </Modal>
            </HeaderPart>
            <Table
                dataSource={farms?.data?.content || []}
                columns={columns}
                pagination={false}
                rowKey={record => `row-${record.id}`}
                onRow={record => ({
                    onClick: () => onRowClick(record),
                })}
            />
            {/*<Pagination onChange={onchange} defaultCurrent={1} total={50} />*/}
        </ListWrapper>
    );
};

const mapStateToProps = state => ({
    farms: state.admin.farms,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            createFarm,
            updateFarm,
            getFarmsList,
        },
        dispatch,
    ),
});

export const FarmList = withRouter(connect(mapStateToProps, mapDispatchToProps)(FarmgroupListRaw));
