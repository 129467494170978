import {Button, Checkbox, Form, Modal} from 'antd';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import find from 'lodash/find';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useStateValue} from '../../store/Provider';
import {fetcher} from '../../rest';
import {exportCommand, exportCommandTest} from '../../reduxStore/modules/units';

const MetricsListWrapper = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 10px;
    margin-right: 80px;
    width: 250px;
    overflow-y: auto;
    margin-bottom: 20px;
    max-height: 400px;
`;

const CheckboxGroup = styled(Checkbox.Group)`
    display: flex;
    flex-direction: column;

    .ant-checkbox-group-item {
        margin-bottom: 10px;
    }
`;

export const MetricsListRaw = props => {
    const [dataList, setDataList] = useState([]);

    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const [{farm}, dispatch] = useStateValue();

    const updateMetrics = data => {
        dispatch({
            type: 'updateMetrics',
            data,
        });
    };

    const getMetrics = (farmId, name, cycle, key) => {
        let query = '';
        if (cycle) {
            query = `?cycleId=${cycle}`;
        } else if (farm.cycle && farm.cycle !== 'current') {
            query = `?cycleId=${farm.cycle}`; // @TODO привести к третьему параметру функции
        }

        return fetcher
            .get(`farms/${farmId}/metric/${name}${query}`)
            .then(data => {
                const cycleStart = data.series[0].x;
                let label;

                dataList.forEach(el => {
                    if (el.value === name) {
                        label = `${key} ${el.label}`;
                        return false;
                    }
                });

                return {
                    name: label,
                    cycleStart,
                    data: data.series.map(el => [el.x - cycleStart, el.y]),
                };
            })
            .catch(error => {
                console.log(error);
            });
    };

    useEffect(() => {
        let query = '?';

        props.selected.forEach((cycle, k) => {
            query += `cycleIds=${cycle.cycle.id}${k !== props.selected.length - 1 ? '&' : ''}`;
        });

        fetcher.get(`/farms/metrics${query}`).then(data => {
            setDataList(
                data.map(el => ({
                    value: el.id,
                    label: el.label,
                })),
            );
        });

        return () => {
            updateMetrics([]);
        };
    }, [props.selected]);

    const handleSubmit = e => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                setLoading(true);
                // @TODO  optimize query without already downloaded metrics
                let queryArray = [];
                const metrics = values.metrics.filter(el => {
                    return find(dataList, ['value', el]);
                });

                if (Array.isArray(props.farmId)) {
                    // compare case
                    props.farmId.forEach((el, key) => {
                        queryArray = [
                            ...queryArray,
                            ...metrics.map(metric => getMetrics(el.farm.id, metric, el.cycle.id, key)),
                        ];
                    });
                }

                Promise.all(queryArray)
                    .then(result => {
                        updateMetrics(result);
                        setVisible(false);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.log(error);
                        setLoading(false);
                    });
            }
        });
    };

    const {getFieldDecorator} = props.form;

    return (
        <>
            <Button type="primary" onClick={() => setVisible(!visible)}>
                Изменить метрики
            </Button>
            <Modal title="Выберите метрики" visible={visible} footer={null} onCancel={() => setVisible(false)}>
                <Form layout="vertical" onSubmit={handleSubmit}>
                    <MetricsListWrapper>
                        <Form.Item>
                            {getFieldDecorator('metrics', {
                                rules: [{type: 'array', required: true, message: 'Нужно выбрать хотя бы одну'}],
                                // initialValue: getDefaultGraphicsValues(dataList),
                            })(<CheckboxGroup options={dataList} />)}
                        </Form.Item>
                    </MetricsListWrapper>

                    <Button type="primary" htmlType="submit" disabled={loading}>
                        Показать
                    </Button>
                </Form>
            </Modal>
        </>
    );
};

export const MetricsListCompareForm = Form.create({name: 'register'})(MetricsListRaw);

const mapStateToProps = state => ({
    selected: state.compare.selected,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            exportCommand,
            exportCommandTest,
        },
        dispatch,
    ),
});

export const MetricsListCompare = connect(mapStateToProps, mapDispatchToProps)(MetricsListCompareForm);
