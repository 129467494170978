import React, {useEffect, useState} from 'react';
import {Icon, Layout, Spin} from 'antd';
import {createGlobalStyle} from 'styled-components';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {ActionType} from 'redux-promise-middleware';
import {SideNav} from './SideNav';
import {getAllUnits} from '../../reduxStore/modules/units';
import {getUserInfo} from '../../reduxStore/modules/user';
import {
    Burger,
    Logo,
    MainLayoutWrapper,
    StyledContentLayout,
    StyledHeader,
    StyledHeaderMenu,
    StyledLayout,
} from './StyledLayout';
import {HeaderMenu} from './HeaderMenu';

const GlobalStyle = createGlobalStyle`
  #root {
    min-height: 100%;
    height: 100%;
  }
  
  .App {
    height: 100%;
    min-height: 100%;
  }
`;

export const MainLayoutRaw = props => {
    const [isSideBarOpened, setSideBarOpened] = useState(false);

    props.history.listen(() => {
        setSideBarOpened(false);
    });

    useEffect(() => {
        props.actions.getAllUnits();
        props.actions.getUserInfo();
    }, []);

    return (
        <Spin spinning={props.units.status !== ActionType.Fulfilled}>
            <MainLayoutWrapper>
                <GlobalStyle />
                <StyledHeader>
                    <Logo />
                    <StyledHeaderMenu>
                        <HeaderMenu />
                    </StyledHeaderMenu>
                    <Burger onClick={() => setSideBarOpened(!isSideBarOpened)}>
                        <Icon type="menu" />
                    </Burger>
                </StyledHeader>
                <StyledContentLayout>
                    <SideNav opened={isSideBarOpened} units={props.units.data} user={props.user} />
                    <StyledLayout>{props.children}</StyledLayout>
                </StyledContentLayout>
            </MainLayoutWrapper>
        </Spin>
    );
};

const mapStateToProps = state => ({
    units: state.units,
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getAllUnits,
            getUserInfo,
        },
        dispatch,
    ),
});

export const MainLayout = connect(mapStateToProps, mapDispatchToProps)(MainLayoutRaw);
