import React from 'react';
import {CycleList} from './CycleList';

export const FarmPage = () => {
    return <CycleList />;
};
//
// const mapStateToProps = state => ({});
//
// const mapDispatchToProps = dispatch => ({
//     actions: bindActionCreators(
//         {
//             getDepartmentById,
//         },
//         dispatch,
//     ),
// });

// export const FarmPage = connect(mapStateToProps, mapDispatchToProps)(FarmPageRaw);
