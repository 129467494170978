import React from 'react';
import {PAGES} from '../../commonConstants';
import {setToken} from '../../rest';
import {StyledNavLink} from './StyledLayout';

const onLogoutClick = () => {
    setToken('');
};

export const HeaderMenu = () => {
    return (
        <>
            <StyledNavLink to={`/${PAGES.CONTACTS}`}>Обратная связь</StyledNavLink>
            {/* <StyledNavLink to={`/${PAGES.USER}`}>Личный кабинет</StyledNavLink> */}
            <StyledNavLink onClick={onLogoutClick} to={`/${PAGES.SIGNIN}`}>
                Выйти
            </StyledNavLink>
        </>
    );
};
