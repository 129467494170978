import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import {Button, DatePicker, Form, Input} from 'antd';

const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const CreateCompanyFormRaw = props => {
    const formItemLayout = {
        labelCol: {span: 8},
        wrapperCol: {span: 12},
    };

    const handleSubmit = e => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                props.onSubmit(values);
            }
        });
    };

    const onDataChange = (date, dateString) => {
        console.log(date, dateString);
    };

    const {getFieldDecorator} = props.form;
    return (
        <Form onSubmit={handleSubmit}>
            <Form.Item {...formItemLayout} label="Название">
                {getFieldDecorator('name', {
                    rules: [{required: true, message: 'Введите название компании!'}],
                    initialValue: props.initialValues.name,
                })(<Input placeholder="Название" />)}
            </Form.Item>
            {/*<Form.Item {...formItemLayout} label="Активна до">*/}
            {/*    {getFieldDecorator('validTo', {*/}
            {/*        rules: [{required: true, message: 'Введите дату!'}],*/}
            {/*        initialValue: props.initialValues.validTo ? moment(props.initialValues.validTo) : undefined,*/}
            {/*    })(<DatePicker placeholder="Валидна до" onChange={onDataChange} style={{width: '100%'}} />)}*/}
            {/*</Form.Item>*/}
            <Form.Item {...formItemLayout} label="Задержка">
                {getFieldDecorator('dataRequestDelayInSeconds', {
                    rules: [{required: true, message: 'Введите частоту запросов'}],
                    initialValue: props.initialValues.dataRequestDelayInSeconds,
                })(<Input placeholder="300 (секунд)" />)}
            </Form.Item>
            <Footer>
                <Button type="primary" htmlType="submit">
                    Создать
                </Button>
            </Footer>
        </Form>
    );
};

export const CreateCompanyForm = Form.create({name: 'createCompany'})(CreateCompanyFormRaw);
