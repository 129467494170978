import React from 'react';
import styled from 'styled-components';

import {Button, Form, Input} from 'antd';

const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const CreateDepartmentFormRaw = props => {
    const formItemLayout = {
        labelCol: {span: 8},
        wrapperCol: {span: 12},
    };

    const handleSubmit = e => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                props.onSubmit(values);
            }
        });
    };

    const {getFieldDecorator} = props.form;
    return (
        <Form onSubmit={handleSubmit}>
            <Form.Item {...formItemLayout} label="Название">
                {getFieldDecorator('name', {
                    rules: [{required: true, message: 'Введите название компании!'}],
                    initialValue: props.initialValues.name,
                })(<Input placeholder="Название" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="Порядок">
                {getFieldDecorator('sortOrder', {
                    rules: [{required: true, message: 'Порядок'}],
                    initialValue: props.initialValues.sortOrder,
                })(<Input placeholder="0" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="Айди компании">
                {getFieldDecorator('companyId', {
                    rules: [{required: true, message: 'создай компанию и скопипасти из урла'}],
                    initialValue: props.initialValues.companyId,
                })(<Input placeholder="Ну тут надо запомнить айдишник пока" />)}
            </Form.Item>
            <Footer>
                <Button type="primary" htmlType="submit">
                    Создать
                </Button>
            </Footer>
        </Form>
    );
};

export const CreateDepartmentForm = Form.create({name: 'createDepartment'})(CreateDepartmentFormRaw);
