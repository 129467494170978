import React, {useState} from 'react';
import {Button, Modal, Pagination, Spin, Table, Typography} from 'antd';
import {ActionType} from 'redux-promise-middleware';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import isEmpty from 'lodash/isEmpty';
import {useRouteMatch} from 'react-router';

import {CreateCompanyForm} from 'components/Forms/CreateCompanyForm';

import {createCompany, getCompanies, updateCompany} from '../../../reduxStore/modules/admin';
import {ActionLink, HeaderPart} from '../Styled';

const {Title} = Typography;


const MainAdminRaw = props => {
    const [visibleModal, setVisability] = useState(false);
    const [editable, setEditable] = useState({});
    const {url} = useRouteMatch();

    const toogleModalState = () => {
        if (visibleModal) {
            setEditable({});
        }
        setVisability(!visibleModal);
    };
    const onEditClick = (e, record) => {
        e.stopPropagation();
        setEditable(record);
        toogleModalState();
    };

    const columns = [
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Задержка',
            dataIndex: 'dataRequestDelayInSeconds',
            key: 'frequency',
        },
        {
            title: 'Действия',
            key: 'operation',
            fixed: 'right',
            width: 200,
            render: (text, record) => <ActionLink onClick={e => onEditClick(e, record)}>Изменить</ActionLink>,
        },
    ];

    const {companies, actions} = props;

    const fetchCompanies = () => {
        actions.getCompanies({});
    };

    const onRowClick = obj => {
        const check = url.slice(-1);
        let origin = url;

        if (check === '/') {
            origin = origin.substr(0, origin.length - 1); // use strict in routing
        }

        props.history.push(`${origin}/${obj.id}`);
    };

    const onSubmitForm = values => {
        if (isEmpty(editable)) {
            actions
                .createCompany(values)
                .then(() => {
                    toogleModalState();
                    fetchCompanies();
                })
                .catch(err => {
                });
        } else {
            actions
                .updateCompany({id: editable.id, data: values})
                .then(() => {
                    toogleModalState();
                    fetchCompanies();
                })
                .catch(err => {
                });
        }
    };

    return (
        <Spin spinning={ActionType.Fulfilled !== companies.status}>
            <HeaderPart>
                <Title>Компании</Title>
                <Button type="primary" onClick={toogleModalState}>
                    Создать новую
                </Button>
                <Modal
                    visible={visibleModal}
                    title="Создание компании"
                    footer={null}
                    onCancel={toogleModalState}
                    destroyOnClose
                >
                    <CreateCompanyForm onSubmit={onSubmitForm} initialValues={editable} />
                </Modal>
            </HeaderPart>
            <Table
                dataSource={companies?.data?.content || []}
                columns={columns}
                pagination={false}
                rowKey={record => `row-${record.id}`}
                onRow={record => ({
                    onClick: () => onRowClick(record),
                })}
            />
            <Pagination onChange={onchange} defaultCurrent={1} total={50} />
        </Spin>
    );
};

const mapStateToProps = state => ({
    companies: state.admin.companies,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getCompanies,
            createCompany,
            updateCompany,
        },
        dispatch,
    ),
});

export const MainAdmin = connect(mapStateToProps, mapDispatchToProps)(MainAdminRaw);
