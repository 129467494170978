import {createAction, handleActions} from 'redux-actions';
import {combineReducers} from 'redux';

const SET_SELECTED_FARMS = 'SET_SELECTED_FARMS';
const DELETE_SELECTED_FARM = 'DELETE_SELECTED_FARM';
const CLEAR_SELECTED_FARMS = 'CLEAR_SELECTED_FARMS';
const initialValues = [];

export const setSelectedFarms = createAction(SET_SELECTED_FARMS);
export const deleteSelectedFarm = createAction(DELETE_SELECTED_FARM);
export const clearSelectedFarms = createAction(CLEAR_SELECTED_FARMS);

const selectedFarmsReducer = handleActions(
    {
        [SET_SELECTED_FARMS]: (state, action) => [...state, action.payload],
        [DELETE_SELECTED_FARM]: (state, action) => {
            const temp = [...state];
            temp.splice(action.payload, 1);
            return temp;
        },
        [CLEAR_SELECTED_FARMS]: () => initialValues,
    },
    initialValues,
);

export const compareReducers = combineReducers({
    selected: selectedFarmsReducer,
});
