import {Breadcrumb} from 'antd';
import React from 'react';
import {connect} from 'react-redux';
import {ActionType} from 'redux-promise-middleware';
import {DepartmentLink, FarmgroupLink} from '../../components/Atoms/Links';

const BreadcrumbsFarmgroupRaw = props => {
    const {farms} = props;

    if (farms.status !== ActionType.Fulfilled) {
        return null;
    }

    const farmgroup = props.farms.data.parent;
    const department = props.farms.data.parent.parent;
    return (
        <Breadcrumb style={{margin: '16px 0'}} key="major1">
            <Breadcrumb.Item>
                <DepartmentLink {...department} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <FarmgroupLink {...farmgroup} />
            </Breadcrumb.Item>
        </Breadcrumb>
    );
};

const mapStateToProps = state => ({
    farms: state.farms,
});

export const BreadcrumbsFarmgroup = connect(mapStateToProps)(BreadcrumbsFarmgroupRaw);
