import moment from 'moment';

export const cycleName = cycle => {
    if (cycle.dateEnd) {
        return `${moment(cycle.dateBegin).format('DD MMM YYYY')} - ${moment(cycle.dateEnd).format('DD MMM YYYY')}`;
    }
    return `${moment(cycle.dateBegin).format('DD MMM YYYY')} - по настоящее время`;
};

export const renderDate = date => moment(date).format('DD.MM.YYYY, HH:mm:ss');

export const priorityList = metric => {
    let rate;

    switch (metric.key) {
        case 'temperature_out':
            rate = 0;
            break;
        case 'temperature_in':
            rate = 2;
            break;
        case 'chicken_livestock':
            rate = 4
            break;
        case 'cock_livestock':
            rate = 6;
            break;
        case 'chicken_mortality':
            rate = 8;
            break;
        case 'cock_mortality':
            rate = 10;
            break;
        case 'chicken_mortality_today':
            rate = 12;
            break;
        case 'cock_mortality_today':
            rate = 14;
            break;
        case 'chicken_weight':
            rate = 16;
            break;
        case 'cock_weight':
            rate = 18;
            break;
        case 'chicken_feed_daily_preset_g':
            rate = 20;
            break;
        case 'cock_feed_daily_preset_g':
            rate = 22;
            break;
        case 'chicken_livestock_on_cycle_start':
            rate = 24;
            break;
        case 'cock_livestock_on_cycle_start':
            rate = 26;
            break;
        case 'water_consumption_per_bird_today_ml':
            rate = 28;
            break;
        case 'today_water_consumption':
            rate = 30;
            break;
        case 'egg_counter':
            rate = 32;
            break;
        case 'common_air_inflow':
            rate = 34;
            break;
        case 'akbars_airing':
            rate = 36;
            break;
        case 'humidity':
            rate = 38;
            break;
        case 'akbars_co2':
            rate = 40;
            break;
        case 'recuperator':
            rate = 42;
            break;
        case 'akbars_performance':
            rate = 44;
            break;
        case 'cooling':
            rate = 46;
            break;
        default:
            rate = 50;
            break;
    }

    return rate;
};

export const ADMIN_ROLE = 'system_administrator';
