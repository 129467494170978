import {Layout} from 'antd';
import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Route, Switch} from 'react-router';
import {connect} from 'react-redux';
import {ActionType} from 'redux-promise-middleware';
import {bindActionCreators} from 'redux';
import {MainAdmin} from './MainPage/Main';
import {CompanyPage} from './CompanyPage/Company';
import {DepartmentPage} from './DepartmentPage/Department';
import {Spinner} from '../../components/Atoms/Spinner';
import {UsersPage} from './Users';
import {UserPage} from './UserPage';
import {getCompanies} from '../../reduxStore/modules/admin';
import {FarmList} from './FarmgroupPage/FarmsList';
import {FarmPage} from './FarmPage/Farm';
import {ADMIN_ROLE} from '../../utils';

const StyledLayout = styled(Layout)`
    padding: 40px;
    background: white;
`;

const AdminRaw = props => {
    const {match, user, history, actions} = props;
    const isAdmin = user?.data?.userRole === ADMIN_ROLE;

    useEffect(() => {
        if (user.status === ActionType.Fulfilled) {
            if (isAdmin) {
                actions.getCompanies({});
            } else {
                history.push('/');
            }
        }
    }, [user]);

    return user.status === ActionType.Fulfilled ? (
        <StyledLayout>
            <Switch>
                <Route path={`${match.url}/`} exact component={MainAdmin} />
                <Route path={`${match.url}/users`} exact component={UsersPage} />
                <Route path={`${match.url}/users/:userId`} exact component={UserPage} />
                <Route path={`${match.url}/:companyId`} exact component={CompanyPage} />
                <Route path={`${match.url}/:companyId/:departmentId`} exact component={DepartmentPage} />
                <Route path={`${match.url}/:companyId/:departmentId/:farmgroupId`} exact component={FarmList} />
                <Route path={`${match.url}/:companyId/:departmentId/:farmgroupId/:farmId`} exact component={FarmPage} />
                <Route component={() => <div>404</div>} />
            </Switch>
        </StyledLayout>
    ) : (
        <Spinner />
    );
};

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getCompanies,
        },
        dispatch,
    ),
});

export const Admin = connect(mapStateToProps, mapDispatchToProps)(AdminRaw);
