import {Layout} from 'antd';
import styled from 'styled-components';
import {NavLink} from 'react-router-dom';
import logoImg from 'images/logo.png';
import variables from '../../utils/variables';

export const {Header} = Layout;

export const Logo = styled.div`
    background: url(${logoImg}) center no-repeat;
    background-size: contain;
    height: 100%;
    width: 45px;
`;

export const StyledHeader = styled(Header)`
    display: flex;
    justify-content: space-between;
    z-index: 100;
    position: fixed;
    width: 100%;
    padding: 0 12px;
`;

export const StyledLayout = styled(Layout)`
    padding: 0 12px 24px;

    @media screen and (min-width: ${variables.screenMd}) {
        margin-left: ${variables.siderWidth};
        padding: 0 24px 24px;
    }
`;

export const StyledContentLayout = styled(Layout)`
    padding-top: ${variables.headerheight};
`;

export const MainLayoutWrapper = styled(Layout)`
    min-height: 100vh;
`;

export const StyledNavLink = styled(NavLink)`
    padding: 10px 15px;
    color: rgba(255, 255, 255, 0.65);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    line-height: 40px;
    cursor: pointer;

    & > i {
        margin-right: 10px;
    }

    &.active {
        color: white;
    }

    &:active,
    &:hover,
    &:focus {
        color: white;
    }
`;

export const StyledHeaderMenu = styled.div`
    display: none;

    @media screen and (min-width: ${variables.screenMd}) {
        display: block;
    }
`;
export const Burger = styled.div`
    color: white;
    cursor: pointer;

    @media screen and (min-width: ${variables.screenMd}) {
        display: none;
    }
`;
