import React, {createContext, useContext, useReducer} from 'react';
import {Provider} from 'react-redux';
import {store} from 'reduxStore';
import {mainReducer} from 'store/Reducer';

export const StateContext = createContext();

const initialState = {
    departments: {},
    users: {},
    alarms: {},
    farm: {},
    units: {},
};

export const StateProvider = ({children}) => (
    <StateContext.Provider value={useReducer(mainReducer, initialState)}>
        <Provider store={store}>{children}</Provider>
    </StateContext.Provider>
);
export const useStateValue = () => useContext(StateContext);
