import React, {useState} from 'react';
import styled from 'styled-components';
// import { ResponsiveLine } from '@nivo/line';
// import moment from 'moment';
import {Switch} from 'antd';
import {MetricsList} from './MetricsList';
import {useStateValue} from '../../store/Provider';
// import {LinearChart} from "./Graph";
// import {GoogleChart} from "./GoogleChart";
import {Hichart} from './Highcharts';

// const Tooltip = styled.div`
//   padding: 5px 10px;
//   box-shadow: 2px 2px 2px 0 rgba(0,0,0,0.75);
//   border: 1px solid black;
//   background: white;
//   border-radius: 10px;
// `;

const ControlPanel = styled.div`
    display: flex;
    justify-content: space-between;
`;

// const MyResponsiveLine = ({ data /* see data tab */ }) => (
//     <ResponsiveLine
//         data={data}
//         margin={{
//             top: 50, right: 160, bottom: 50, left: 60,
//         }}
//         xScale={{
//             type: 'time',
//             // format: '%x',
//         }}
//         xFormat="time:%d/%m/%Y %H:%M"
//         yScale={{
//             type: 'linear', min: 'auto', max: 'auto',
//         }}
//         curve="monotoneX"
//         axisTop={null}
//         axisRight={{
//             // tickSize: 5,
//             tickPadding: 5,
//             tickRotation: 0,
//             format: '.2s',
//             legend: '',
//             tickValues: 5,
//             legendOffset: 0,
//         }}
//         axisBottom={{
//             format: value => moment(value).format('D MMM'),
//             tickSize: 10,
//             legend: 'Дата',
//             tickValues: 10, // dynamic render width
//             legendOffset: 36,
//             legendPosition: 'middle',
//         }}
//         axisLeft={{
//             tickSize: 5,
//             tickPadding: 5,
//             tickRotation: 0,
//             legend: 'Количество',
//             legendOffset: -50,
//             legendPosition: 'middle',
//         }}
//         enableGridX={false}
//         width={900}
//         height={400}
//         colors={{ scheme: 'nivo' }}
//         lineWidth={1}
//         enablePoints={false}
//         // pointSize={4}
//         // pointColor={{ theme: 'background' }}
//         // pointBorderWidth={1}
//         // pointBorderColor={{ from: 'serieColor' }}
//         // // enablePointLabel={false}
//         // pointLabel="y"
//         // pointLabelYOffset={-12}
//         useMesh
//         tooltip={el => (
//             <Tooltip>
//                 <div>Дата: {el.point.data.xFormatted}</div>
//                 <div>Кол-во: {el.point.data.y}</div>
//             </Tooltip>
//         )}
//         gridYValues={5}
//         legends={[
//             {
//                 anchor: 'bottom-right',
//                 direction: 'column',
//                 justify: false,
//                 translateX: 140,
//                 translateY: 0,
//                 itemsSpacing: 2,
//                 itemDirection: 'left-to-right',
//                 itemWidth: 80,
//                 itemHeight: 12,
//                 itemOpacity: 0.75,
//                 symbolSize: 12,
//                 symbolShape: 'circle',
//                 symbolBorderColor: 'rgba(0, 0, 0, .5)',
//                 effects: [
//                     {
//                         on: 'hover',
//                         style: {
//                             itemBackground: 'rgba(0, 0, 0, .03)',
//                             itemOpacity: 1,
//                         },
//                     },
//                 ],
//             },
//         ]}
//     />
// );

const GraphWrapper = styled.div``;

export const Metrics = props => {
    const [{farm}] = useStateValue();
    const [darkTheme, setDarkTheme] = useState(false);

    return (
        <GraphWrapper>
            <ControlPanel>
                <MetricsList key={props.cycle} data={props.data.state.metrics} farmId={props.data.id} />
                <span>
                    Темная тема <Switch onChange={setDarkTheme} checked={darkTheme} />
                </span>
            </ControlPanel>
            <br />
            <Hichart data={farm.metrics} darkTheme={darkTheme} key={darkTheme ? 'dark' : 'default'} />
            {/* <LinearChart /> */}
            {/* <GoogleChart/> */}
            {/* {farm.metrics ? <MyResponsiveLine data={farm.metrics} /> : <Spinner />} */}
        </GraphWrapper>
    );
};
