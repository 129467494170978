import {Breadcrumb, Button} from 'antd';
import React, {useEffect} from 'react';
import {FarmgroupsList} from 'containers/FarmgroupsList';
import {ActionType} from 'redux-promise-middleware';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {useRouteMatch} from 'react-router-dom';
import {DepartmentLink} from '../../components/Atoms/Links/Department';
import {Spinner} from '../../components/Atoms/Spinner';
import {exportCommand, exportCommandTest} from '../../reduxStore/modules/units';
import {clearFarmgroups, getFarmgroups} from '../../reduxStore/modules/units/farmgroups';
import {HeaderWithActions, StyledContent} from './StyledDepartment';

export const DepartmentRaw = ({actions, farmgroups}) => {
    const match = useRouteMatch();

    useEffect(() => {
        actions.clearFarmgroups();

        actions.getFarmgroups(match.params.id);
    }, [match.params.id]);

    return farmgroups.status === ActionType.Fulfilled ? (
        <>
            <HeaderWithActions>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <Breadcrumb.Item>
                        <DepartmentLink {...farmgroups.data.parent} />
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Button type="primary" icon="cloud-download" onClick={() => actions.exportCommandTest(match.params.id)}>
                    Выгрузить
                </Button>
            </HeaderWithActions>
            <StyledContent key="major2">
                {farmgroups.data.items.length > 0 ? (
                    <FarmgroupsList currentDepartment={match.params.id} data={farmgroups.data.items} />
                ) : (
                    'Нет данных'
                )}
            </StyledContent>
        </>
    ) : (
        <Spinner spinning={farmgroups.status !== ActionType.Fulfilled} />
    );
};

const mapStateToProps = state => ({
    units: state.units,
    farmgroups: state.farmgroups,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            exportCommand,
            exportCommandTest,
            getFarmgroups,
            clearFarmgroups,
        },
        dispatch,
    ),
});

export const Department = connect(mapStateToProps, mapDispatchToProps)(DepartmentRaw);
