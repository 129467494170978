import React, {useEffect} from 'react';
import {Button, Pagination, Popconfirm, Table, Typography} from 'antd';

import {useRouteMatch, withRouter} from 'react-router';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import moment from 'moment';
import {ActionType} from 'redux-promise-middleware';
import {ActionLink, HeaderPart, ListWrapper} from '../Styled';
import {closeCycle, deleteFarmCycles, getFarmCycles, getFarmInfo, openCycle} from '../../../reduxStore/modules/house';

const {Title} = Typography;

const CycleListRaw = props => {
    const {actions, farmInfo, cycles} = props;
    const match = useRouteMatch();

    const onDeleteClick = (e, record) => {
        e.stopPropagation();
        props.actions.deleteFarmCycles([record.id]).then(() => {
            actions.getFarmCycles(match.params.farmId);
        });
    };

    const refresh = () => {
        actions.getFarmInfo(match.params.farmId);
        actions.getFarmCycles(match.params.farmId);
    };
    useEffect(() => {
        refresh();
    }, [match.params.farmId]);

    const fetchFarmsCycles = () => {
        actions.getFarmCycles(props.match.params.farmId);
    };

    function handleOpenCycle() {
        actions.openCycle(match.params.farmId).then(() => {
            refresh();
        });
    }
    function handleCloseCycle() {
        actions.closeCycle(match.params.farmId).then(() => {
            refresh();
        });
    }

    useEffect(() => {
        fetchFarmsCycles();
    }, []);

    const columns = [
        {
            title: 'Наименование',
            dataIndex: 'label',
            key: 'label',
        },
        {
            title: 'Начало',
            dataIndex: 'dateBegin',
            key: 'dateBegin',
            render: text => moment(text).format('DD.MM.YYYY HH:mm:ss'),
        },
        {
            title: 'Конец',
            dataIndex: 'dateEnd',
            key: 'dateEnd',
            render: text => moment(text).format('DD.MM.YYYY HH:mm:ss'),
        },
        {
            title: 'Действия',
            key: 'operation',
            fixed: 'right',
            width: 150,
            render: (text, record) => <ActionLink onClick={e => onDeleteClick(e, record)}>Удалить</ActionLink>,
        },
    ];

    return (
        <ListWrapper>
            <HeaderPart>
                <Title>Циклы</Title>
                {farmInfo.status === ActionType.Fulfilled &&
                    (farmInfo.data.isCycleOpen ? (
                        <Popconfirm
                            title="Вы точно хотите закрыть цикл?"
                            onConfirm={handleCloseCycle}
                            okText="Да"
                            cancelText="Нет"
                        >
                            <Button type="primary">Закрыть цикл</Button>
                        </Popconfirm>
                    ) : (
                        <Popconfirm
                            title="Вы точно хотите открыть цикл?"
                            onConfirm={handleOpenCycle}
                            okText="Да"
                            cancelText="Нет"
                        >
                            <Button type="primary">Открыть цикл</Button>
                        </Popconfirm>
                    ))}
            </HeaderPart>
            <Table
                dataSource={cycles?.data?.items || []}
                columns={columns}
                pagination={false}
                rowKey={record => `row-${record.id}`}
            />
            <Pagination onChange={onchange} defaultCurrent={1} total={50} />
        </ListWrapper>
    );
};

const mapStateToProps = state => ({
    cycles: state.house.cycles,
    farmInfo: state.house.farmInfo,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getFarmCycles,
            getFarmInfo,
            openCycle,
            closeCycle,
            deleteFarmCycles,
        },
        dispatch,
    ),
});

export const CycleList = withRouter(connect(mapStateToProps, mapDispatchToProps)(CycleListRaw));
