import {createAction} from 'redux-actions';
import {fetcher} from 'rest';

import {handleApiActions, initialState} from '../../utils';
import sortBy from "lodash/sortBy";
import {priorityList} from "../../../utils";

const GET_DEPARTMENTS_BY_DEPARTMENT = 'GET_DEPARTMENTS_BY_DEPARTMENT';
const CLEAR_DEPARTMENTS = 'CLEAR_DEPARTMENTS';

export const getDepartments = createAction(GET_DEPARTMENTS_BY_DEPARTMENT, id =>
    new Promise((res, rej) => {
        fetcher.get(`/departments`)
            .then(data => {
                data.items.map(dep => {
                    const mapped = Object.entries(dep.state.metrics).map(([el, val], index) => ({key: el, ...val}))

                    dep.state.metricsList = sortBy(mapped, val => priorityList(val))
                })
                res(data)
            })
            .catch(err => rej(err))
    })
);
export const clearDepartments = createAction(CLEAR_DEPARTMENTS);

export const departmentsReducer = handleApiActions(
    GET_DEPARTMENTS_BY_DEPARTMENT,
    {
        [CLEAR_DEPARTMENTS]: () => initialState,
    },
    initialState,
);
