export const alarmReducer = (state, action) => {
    switch (action.type) {
    case 'GET_ALARMS':
        return {
            ...state,
            alarms: action.data,
        };
    case 'GET_ALARM':
        return {
            ...state,
            alarm: action.data,
        };
    case 'UPDATE_COMMENTS':
        return {
            ...state,
            comments: action.data,
        };
    case 'ADD_COMMENT':
        return {
            ...state,
            comments: {
                ...state.comments,
                items: [action.data, ...state.comments.items],
            },
        };
    default:
        return state;
    }
};
