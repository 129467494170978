import React from 'react';
import {Typography} from 'antd';
import styled from 'styled-components';

const {Text} = Typography;
const {Title} = Typography;

const ContactsPageWrapper = styled.div`
    padding: 40px;
`;

const Avatar = styled.div`
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: url('https://pp.userapi.com/c636625/v636625666/1433c/HFysh47p8wI.jpg') no-repeat center;
    background-size: cover;
`;

const Wrapper = styled.div`
    margin-top: 30px;
`;

const StyledText = styled(Text)`
    font-size: 16px;
`;

export const Contacts = () => (
    <ContactsPageWrapper>
        <Title>Наши контакты</Title>
        <StyledText>
            Дорогие пользователи, если Вам необходима помощь специалиста, обнаружили проблему или хотите оставить отзыв
            - свяжитесь с нами! <br /> Мы всегда рады дать обратную связь.
        </StyledText>
        <Wrapper>
            <StyledText>
                Почта: <a href="mailto:cloudd@agroo.ru">cloudd@agroo.ru</a>
            </StyledText>
            <br />
            <StyledText>
                Телефон: <a href="tel:+79872155955">+7 (987) 215 59 55</a>
            </StyledText>
        </Wrapper>
    </ContactsPageWrapper>
);
