import React, {useEffect} from 'react';
import {Tile, TilesWrapper} from 'components/Tile';
import {Icon} from 'antd';
import {Redirect} from "react-router";

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {PAGES} from '../../commonConstants/constants';
import {exportCommand, exportCommandTest} from '../../reduxStore/modules/units';

const DepartmentsListRaw = ({data, actions}) => {
    return <TilesWrapper>
        {data.length === 1 && <Redirect to={`/${PAGES.DEPARTMENT}/${data[0].id}`} />}
        {data.map(depart => (
            <Tile
                key={depart.id}
                title={depart.name}
                id={depart.id}
                link={`/${PAGES.DEPARTMENT}/${depart.id}`}
                state={depart.state}
                // totalCount={depart.state.metrics.metric.livestock_count}
                actions={[
                    {
                        name: 'Выгрузить',
                        value: 'export',
                        id: depart.id,
                        icon: <Icon type="download" />,
                        callback: () => {
                            actions.exportCommandTest(depart.id);
                        },
                    },
                ]}
            />
        ))}
    </TilesWrapper>
};

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            exportCommand,
            exportCommandTest,
        },
        dispatch,
    ),
});

export const DepartmentsList = connect(null, mapDispatchToProps)(DepartmentsListRaw);
