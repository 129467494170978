import {Layout, Breadcrumb, Button} from 'antd';
import React, {useEffect} from 'react';
import styled from 'styled-components';
import {FarmsList} from 'containers/FarmsList';
import {connect} from 'react-redux';
import {ActionType} from 'redux-promise-middleware';
import {Spinner} from 'components/Atoms/Spinner';
import {bindActionCreators} from 'redux';
import {useRouteMatch} from 'react-router-dom';
import {BreadcrumbsFarmgroup} from './Breadcrumbs';
import {clearFarms, getFarms} from '../../reduxStore/modules/units/farms';
import {exportFarmgroupCommandTest} from '../../reduxStore/modules/units';
import variables from '../../utils/variables';

const {Content} = Layout;

const StyledContent = styled(Content)`
    background: #fff;
    padding: 0;
    margin: 0;
    min-height: 280px;
    position: relative;

    @media screen and (min-width: ${variables.screenMd}) {
        padding: 24px;
    }
`;

const HeaderWithActions = styled(Content)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const FarmgroupRaw = props => {
    const match = useRouteMatch();

    useEffect(() => {
        props.actions.getFarms(match.params.farmgroupId);

        return props.actions.clearFarms;
    }, [match.params.farmgroupId]);

    const farms = props?.farms?.data?.items || [];

    return (
        <Spinner spinning={props.farms.status !== ActionType.Fulfilled}>
            <HeaderWithActions>
                <Breadcrumb style={{margin: '16px 0'}}>
                    <BreadcrumbsFarmgroup />
                </Breadcrumb>
                <Button
                    type="primary"
                    icon="cloud-download"
                    onClick={() => props.actions.exportFarmgroupCommandTest(props.match.params.farmgroupId)}
                >
                    Выгрузить
                </Button>
            </HeaderWithActions>
            <StyledContent key="major2">
                {farms.length > 0 ? <FarmsList currentDepartment={props.match.params.id} data={farms} /> : 'Нет данных'}
            </StyledContent>
        </Spinner>
    );
};

const mapStateToProps = state => ({
    farms: state.farms,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getFarms,
            clearFarms,
            exportFarmgroupCommandTest,
        },
        dispatch,
    ),
});

export const Farmgroup = connect(mapStateToProps, mapDispatchToProps)(FarmgroupRaw);
