import React from 'react';
import styled from 'styled-components';
import {Button, Form, Input, Select} from 'antd';

const {Option} = Select;

const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const ErrorMessage = styled.div`
    color: red;
    text-align: center;
`;

const CreateUserFormRaw = props => {
    const formItemLayout = {
        labelCol: {span: 8},
        wrapperCol: {span: 12},
    };

    const handleSubmit = e => {
        e.preventDefault();
        props.form.validateFields((err, {password, repeatPassword, ...values}) => {
            if (!err) {
                props.onSubmit({...values, password: {password, repeatPassword}});
            }
        });
    };

    const compareToFirstPassword = (rule, value, callback) => {
        const {form} = props;
        if (value && value !== form.getFieldValue('password')) {
            callback('Пароли должны совпадать!');
        } else {
            callback();
        }
    };
    const {getFieldDecorator} = props.form;
    return (
        <Form onSubmit={handleSubmit}>
            <Form.Item {...formItemLayout} label="ФИО">
                {getFieldDecorator('fullName', {
                    rules: [{required: true, message: 'Введите ФИО!'}],
                    initialValue: props.initialValues.fullName,
                })(<Input placeholder="ФИО" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="Компания">
                {getFieldDecorator('companyId', {
                    rules: [{required: true, message: 'Укажите компанию!'}],
                    initialValue: props.initialValues.companyId,
                })(
                    <Select>
                        {props.companies.map(company => (
                            <Option value={company.id}>{company.name}</Option>
                        ))}
                    </Select>,
                )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="Должность">
                {getFieldDecorator('position', {
                    rules: [{required: true, message: 'Введите должность!'}],
                    initialValue: props.initialValues.companyPosition,
                })(<Input placeholder="Должность" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="Роль">
                {getFieldDecorator('userRole', {
                    rules: [{required: true, message: 'Укажите роль!'}],
                    initialValue: props.initialValues.role || 'company_user',
                })(
                    <Select>
                        <Option value="company_user">Простой юзер</Option>
                        <Option value="company_administrator">Администратор</Option>
                        <Option value="system_administrator">Бог</Option>
                    </Select>,
                )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="Эл. почта">
                {getFieldDecorator('email', {
                    rules: [
                        {
                            type: 'email',
                            message: 'Введите корректный email',
                        },
                        {required: true, message: 'Введите почту!'},
                    ],
                    initialValue: props.initialValues.email,
                })(<Input placeholder="E-mail" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="Telegram">
                {getFieldDecorator('telegramAlias', {
                    initialValue: props.initialValues.telegramAlias,
                })(<Input placeholder="Telegram" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="Телефон">
                {getFieldDecorator('phoneNumber', {
                    initialValue: props.initialValues.phoneNumber,
                })(<Input placeholder="7 (ххх) ххх-хх-хх" />)}
            </Form.Item>
            {props.edit && (
                <>
                    <Form.Item {...formItemLayout} label="Пароль">
                        {getFieldDecorator('password', {
                            rules: [{required: true, message: 'Введите пароль!'}],
                        })(<Input type="password" placeholder="Пароль" />)}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="Повторите пароль">
                        {getFieldDecorator('repeatPassword', {
                            rules: [
                                {required: true, message: 'Введите пароль ещё раз!'},
                                {
                                    validator: compareToFirstPassword,
                                },
                            ],
                        })(<Input type="password" placeholder="Пароль" />)}
                    </Form.Item>
                </>
            )}
            {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
            <Footer>
                <Button type="primary" htmlType="submit">
                    Создать
                </Button>
            </Footer>
        </Form>
    );
};

export const CreateUserForm = Form.create({name: 'createUser'})(CreateUserFormRaw);
