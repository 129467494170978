import {Icon, Layout} from 'antd';
import styled from 'styled-components';
import React from 'react';
import variables from '../../utils/variables';

export const antIcon = <Icon type="loading" style={{fontSize: 24}} spin />;

const {Content} = Layout;

export const HeaderWithActions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
`;

export const StyledContent = styled(Content)`
    background: #fff;
    padding: 0;
    margin: 0;
    min-height: 280px;
    position: relative;

    @media screen and (min-width: ${variables.screenMd}) {
        padding: 24px;
    }
`;
