export const unitsReducer = (state, action) => {
    switch (action.type) {
        case 'updateUnits':
            return {
                ...state,
                units: action.data,
            };
        case 'updateCurrentCycle':
            return {
                ...state,
                cycle: action.data,
            };
        case 'select':
            return {
                ...state,
                selected: action.data,
            };
        case 'clearSelect':
            return {
                ...state,
                selected: [],
            };
        case 'updateUnitsMetric':
            return {
                ...state,
                metrics: action.data,
            };
        default:
            return state;
    }
};
