export const defaultTheme = {
    colors: [
        '#7cb5ec',
        '#434348',
        '#90ed7d',
        '#f7a35c',
        '#8085e9',
        '#f15c80',
        '#e4d354',
        '#2b908f',
        '#f45b5b',
        '#91e8e1',
    ],
    title: {
        style: {
            color: '#333',
        },
    },
    chart: {
        styledMode: false,
        borderRadius: 0,
        colorCount: 10,
        defaultSeriesType: 'line',
        ignoreHiddenSeries: true,
        spacing: [10, 10, 15, 10],
        resetZoomButton: {
            theme: {
                zIndex: 6,
            },
            position: {
                align: 'right',
                x: -10,
                y: 10,
            },
        },
        width: null,
        height: null,
        borderColor: '#335cad',
        backgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
    },
    xAxis: {
        gridLineColor: '#e6e6e6',
        lineColor: '#e6e6e6',
    },
    yAxis: {
        gridLineColor: '#e6e6e6',
        lineColor: '#e6e6e6',
    },
    plotOptions: {
        line: {
            lineWidth: 2,
            allowPointSelect: false,
            showCheckbox: false,
            animation: {
                duration: 1000,
            },
            events: {},
            marker: {
                enabledThreshold: 2,
                lineColor: '#ffffff',
                lineWidth: 0,
                radius: 4,
                states: {
                    normal: {
                        animation: true,
                    },
                    hover: {
                        animation: {
                            duration: 50,
                        },
                        enabled: true,
                        radiusPlus: 2,
                        lineWidthPlus: 1,
                    },
                    select: {
                        fillColor: '#cccccc',
                        lineColor: '#000000',
                        lineWidth: 2,
                    },
                },
            },
            point: {
                events: {},
            },
            dataLabels: {
                align: 'center',
                padding: 5,
                style: {
                    fontSize: '11px',
                    fontWeight: 'bold',
                    color: 'contrast',
                    textOutline: '1px contrast',
                },
                verticalAlign: 'bottom',
                x: 0,
                y: 0,
            },
            cropThreshold: 300,
            opacity: 1,
            pointRange: 0,
            softThreshold: true,
            states: {
                normal: {
                    animation: true,
                },
                hover: {
                    animation: {
                        duration: 50,
                    },
                    lineWidthPlus: 1,
                    marker: {},
                    halo: {
                        size: 10,
                        opacity: 0.25,
                    },
                },
                select: {
                    animation: {
                        duration: 0,
                    },
                },
                inactive: {
                    animation: {
                        duration: 50,
                    },
                    opacity: 0.2,
                },
            },
            stickyTracking: true,
            turboThreshold: 1000,
            findNearestPointBy: 'x',
        },
        area: {
            lineWidth: 2,
            allowPointSelect: false,
            showCheckbox: false,
            animation: {
                duration: 1000,
            },
            events: {},
            marker: {
                enabledThreshold: 2,
                lineColor: '#ffffff',
                lineWidth: 0,
                radius: 4,
                states: {
                    normal: {
                        animation: true,
                    },
                    hover: {
                        animation: {
                            duration: 50,
                        },
                        enabled: true,
                        radiusPlus: 2,
                        lineWidthPlus: 1,
                    },
                    select: {
                        fillColor: '#cccccc',
                        lineColor: '#000000',
                        lineWidth: 2,
                    },
                },
            },
            point: {
                events: {},
            },
            dataLabels: {
                align: 'center',
                padding: 5,
                style: {
                    fontSize: '11px',
                    fontWeight: 'bold',
                    color: 'contrast',
                    textOutline: '1px contrast',
                },
                verticalAlign: 'bottom',
                x: 0,
                y: 0,
            },
            cropThreshold: 300,
            opacity: 1,
            pointRange: 0,
            softThreshold: false,
            states: {
                normal: {
                    animation: true,
                },
                hover: {
                    animation: {
                        duration: 50,
                    },
                    lineWidthPlus: 1,
                    marker: {},
                    halo: {
                        size: 10,
                        opacity: 0.25,
                    },
                },
                select: {
                    animation: {
                        duration: 0,
                    },
                },
                inactive: {
                    animation: {
                        duration: 50,
                    },
                    opacity: 0.2,
                },
            },
            stickyTracking: true,
            turboThreshold: 1000,
            findNearestPointBy: 'x',
            threshold: 0,
        },
        spline: {
            lineWidth: 2,
            allowPointSelect: false,
            showCheckbox: false,
            animation: {
                duration: 1000,
            },
            events: {},
            marker: {
                enabledThreshold: 2,
                lineColor: '#ffffff',
                lineWidth: 0,
                radius: 4,
                states: {
                    normal: {
                        animation: true,
                    },
                    hover: {
                        animation: {
                            duration: 50,
                        },
                        enabled: true,
                        radiusPlus: 2,
                        lineWidthPlus: 1,
                    },
                    select: {
                        fillColor: '#cccccc',
                        lineColor: '#000000',
                        lineWidth: 2,
                    },
                },
            },
            point: {
                events: {},
            },
            dataLabels: {
                align: 'center',
                padding: 5,
                style: {
                    fontSize: '11px',
                    fontWeight: 'bold',
                    color: 'contrast',
                    textOutline: '1px contrast',
                },
                verticalAlign: 'bottom',
                x: 0,
                y: 0,
            },
            cropThreshold: 300,
            opacity: 1,
            pointRange: 0,
            softThreshold: true,
            states: {
                normal: {
                    animation: true,
                },
                hover: {
                    animation: {
                        duration: 50,
                    },
                    lineWidthPlus: 1,
                    marker: {},
                    halo: {
                        size: 10,
                        opacity: 0.25,
                    },
                },
                select: {
                    animation: {
                        duration: 0,
                    },
                },
                inactive: {
                    animation: {
                        duration: 50,
                    },
                    opacity: 0.2,
                },
            },
            stickyTracking: true,
            turboThreshold: 1000,
            findNearestPointBy: 'x',
        },
        areaspline: {
            lineWidth: 2,
            allowPointSelect: false,
            showCheckbox: false,
            animation: {
                duration: 1000,
            },
            events: {},
            marker: {
                enabledThreshold: 2,
                lineColor: '#ffffff',
                lineWidth: 0,
                radius: 4,
                states: {
                    normal: {
                        animation: true,
                    },
                    hover: {
                        animation: {
                            duration: 50,
                        },
                        enabled: true,
                        radiusPlus: 2,
                        lineWidthPlus: 1,
                    },
                    select: {
                        fillColor: '#cccccc',
                        lineColor: '#000000',
                        lineWidth: 2,
                    },
                },
            },
            point: {
                events: {},
            },
            dataLabels: {
                align: 'center',
                padding: 5,
                style: {
                    fontSize: '11px',
                    fontWeight: 'bold',
                    color: 'contrast',
                    textOutline: '1px contrast',
                },
                verticalAlign: 'bottom',
                x: 0,
                y: 0,
            },
            cropThreshold: 300,
            opacity: 1,
            pointRange: 0,
            softThreshold: false,
            states: {
                normal: {
                    animation: true,
                },
                hover: {
                    animation: {
                        duration: 50,
                    },
                    lineWidthPlus: 1,
                    marker: {},
                    halo: {
                        size: 10,
                        opacity: 0.25,
                    },
                },
                select: {
                    animation: {
                        duration: 0,
                    },
                },
                inactive: {
                    animation: {
                        duration: 50,
                    },
                    opacity: 0.2,
                },
            },
            stickyTracking: true,
            turboThreshold: 1000,
            findNearestPointBy: 'x',
            threshold: 0,
        },
        column: {
            lineWidth: 2,
            allowPointSelect: false,
            showCheckbox: false,
            animation: {
                duration: 1000,
            },
            events: {},
            marker: null,
            point: {
                events: {},
            },
            dataLabels: {
                align: null,
                padding: 5,
                style: {
                    fontSize: '11px',
                    fontWeight: 'bold',
                    color: 'contrast',
                    textOutline: '1px contrast',
                },
                verticalAlign: null,
                x: 0,
                y: null,
            },
            cropThreshold: 50,
            opacity: 1,
            pointRange: null,
            softThreshold: false,
            states: {
                normal: {
                    animation: true,
                },
                hover: {
                    animation: {
                        duration: 50,
                    },
                    lineWidthPlus: 1,
                    marker: {},
                    halo: false,
                    brightness: 0.1,
                },
                select: {
                    animation: {
                        duration: 0,
                    },
                    color: '#cccccc',
                    borderColor: '#000000',
                },
                inactive: {
                    animation: {
                        duration: 50,
                    },
                    opacity: 0.2,
                },
            },
            stickyTracking: false,
            turboThreshold: 1000,
            findNearestPointBy: 'x',
            borderRadius: 0,
            crisp: true,
            groupPadding: 0.2,
            pointPadding: 0.1,
            minPointLength: 0,
            startFromThreshold: true,
            tooltip: {
                distance: 6,
            },
            threshold: 0,
            borderColor: '#ffffff',
        },
        bar: {
            lineWidth: 2,
            allowPointSelect: false,
            showCheckbox: false,
            animation: {
                duration: 1000,
            },
            events: {},
            marker: null,
            point: {
                events: {},
            },
            dataLabels: {
                align: null,
                padding: 5,
                style: {
                    fontSize: '11px',
                    fontWeight: 'bold',
                    color: 'contrast',
                    textOutline: '1px contrast',
                },
                verticalAlign: null,
                x: 0,
                y: null,
            },
            cropThreshold: 50,
            opacity: 1,
            pointRange: null,
            softThreshold: false,
            states: {
                normal: {
                    animation: true,
                },
                hover: {
                    animation: {
                        duration: 50,
                    },
                    lineWidthPlus: 1,
                    marker: {},
                    halo: false,
                    brightness: 0.1,
                },
                select: {
                    animation: {
                        duration: 0,
                    },
                    color: '#cccccc',
                    borderColor: '#000000',
                },
                inactive: {
                    animation: {
                        duration: 50,
                    },
                    opacity: 0.2,
                },
            },
            stickyTracking: false,
            turboThreshold: 1000,
            findNearestPointBy: 'x',
            borderRadius: 0,
            crisp: true,
            groupPadding: 0.2,
            pointPadding: 0.1,
            minPointLength: 0,
            startFromThreshold: true,
            tooltip: {
                distance: 6,
            },
            threshold: 0,
            borderColor: '#ffffff',
        },
        scatter: {
            lineWidth: 0,
            allowPointSelect: false,
            showCheckbox: false,
            animation: {
                duration: 1000,
            },
            events: {},
            marker: {
                enabledThreshold: 2,
                lineColor: '#ffffff',
                lineWidth: 0,
                radius: 4,
                states: {
                    normal: {
                        animation: true,
                    },
                    hover: {
                        animation: {
                            duration: 50,
                        },
                        enabled: true,
                        radiusPlus: 2,
                        lineWidthPlus: 1,
                    },
                    select: {
                        fillColor: '#cccccc',
                        lineColor: '#000000',
                        lineWidth: 2,
                    },
                },
                enabled: true,
            },
            point: {
                events: {},
            },
            dataLabels: {
                align: 'center',
                padding: 5,
                style: {
                    fontSize: '11px',
                    fontWeight: 'bold',
                    color: 'contrast',
                    textOutline: '1px contrast',
                },
                verticalAlign: 'bottom',
                x: 0,
                y: 0,
            },
            cropThreshold: 300,
            opacity: 1,
            pointRange: 0,
            softThreshold: true,
            states: {
                normal: {
                    animation: true,
                },
                hover: {
                    animation: {
                        duration: 50,
                    },
                    lineWidthPlus: 1,
                    marker: {},
                    halo: {
                        size: 10,
                        opacity: 0.25,
                    },
                },
                select: {
                    animation: {
                        duration: 0,
                    },
                },
                inactive: {
                    animation: {
                        duration: 50,
                    },
                    opacity: 0.2,
                },
            },
            stickyTracking: true,
            turboThreshold: 1000,
            findNearestPointBy: 'xy',
            jitter: {
                x: 0,
                y: 0,
            },
            tooltip: {
                headerFormat:
                    '<span style="color:{point.color}">●</span> <span style="font-size: 10px"> {series.name}</span><br/>',
                pointFormat: 'x: <b>{point.x}</b><br/>y: <b>{point.y}</b><br/>',
            },
        },
        pie: {
            allowPointSelect: false,
            showCheckbox: false,
            animation: {
                duration: 1000,
            },
            events: {},
            marker: null,
            point: {
                events: {},
            },
            dataLabels: {
                align: 'center',
                padding: 5,
                style: {
                    fontSize: '11px',
                    fontWeight: 'bold',
                    color: 'contrast',
                    textOutline: '1px contrast',
                },
                verticalAlign: 'bottom',
                x: 0,
                y: 0,
                allowOverlap: true,
                connectorPadding: 5,
                connectorShape: 'fixedOffset',
                crookDistance: '70%',
                distance: 30,
                enabled: true,
                softConnector: true,
            },
            cropThreshold: 300,
            opacity: 1,
            pointRange: 0,
            softThreshold: true,
            states: {
                normal: {
                    animation: true,
                },
                hover: {
                    animation: {
                        duration: 50,
                    },
                    lineWidthPlus: 1,
                    marker: {},
                    halo: {
                        size: 10,
                        opacity: 0.25,
                    },
                    brightness: 0.1,
                },
                select: {
                    animation: {
                        duration: 0,
                    },
                },
                inactive: {
                    animation: {
                        duration: 50,
                    },
                    opacity: 0.2,
                },
            },
            stickyTracking: false,
            turboThreshold: 1000,
            findNearestPointBy: 'x',
            center: [null, null],
            clip: false,
            colorByPoint: true,
            ignoreHiddenPoint: true,
            inactiveOtherPoints: true,
            legendType: 'point',
            size: null,
            showInLegend: false,
            slicedOffset: 10,
            tooltip: {
                followPointer: true,
            },
            borderColor: '#ffffff',
            borderWidth: 1,
        },
        ohlc: {
            lineWidth: 1,
            allowPointSelect: false,
            showCheckbox: false,
            animation: {
                duration: 1000,
            },
            events: {},
            marker: null,
            point: {
                events: {},
            },
            dataLabels: {
                align: null,
                padding: 5,
                style: {
                    fontSize: '11px',
                    fontWeight: 'bold',
                    color: 'contrast',
                    textOutline: '1px contrast',
                },
                verticalAlign: null,
                x: 0,
                y: null,
            },
            cropThreshold: 50,
            opacity: 1,
            pointRange: null,
            softThreshold: false,
            states: {
                normal: {
                    animation: true,
                },
                hover: {
                    animation: {
                        duration: 50,
                    },
                    lineWidthPlus: 1,
                    marker: {},
                    halo: false,
                    brightness: 0.1,
                    lineWidth: 3,
                },
                select: {
                    animation: {
                        duration: 0,
                    },
                    color: '#cccccc',
                    borderColor: '#000000',
                },
                inactive: {
                    animation: {
                        duration: 50,
                    },
                    opacity: 0.2,
                },
            },
            stickyTracking: true,
            turboThreshold: 1000,
            findNearestPointBy: 'x',
            borderRadius: 0,
            crisp: true,
            groupPadding: 0.2,
            pointPadding: 0.1,
            minPointLength: 0,
            startFromThreshold: true,
            tooltip: {
                distance: 6,
                pointFormat:
                    '<span style="color:{point.color}">●</span> <b> {series.name}</b><br/>Open: {point.open}<br/>High: {point.high}<br/>Low: {point.low}<br/>Close: {point.close}<br/>',
            },
            threshold: null,
            borderColor: '#ffffff',
        },
        candlestick: {
            lineWidth: 1,
            allowPointSelect: false,
            showCheckbox: false,
            animation: {
                duration: 1000,
            },
            events: {},
            marker: null,
            point: {
                events: {},
            },
            dataLabels: {
                align: null,
                padding: 5,
                style: {
                    fontSize: '11px',
                    fontWeight: 'bold',
                    color: 'contrast',
                    textOutline: '1px contrast',
                },
                verticalAlign: null,
                x: 0,
                y: null,
            },
            cropThreshold: 50,
            opacity: 1,
            pointRange: null,
            softThreshold: false,
            states: {
                normal: {
                    animation: true,
                },
                hover: {
                    animation: {
                        duration: 50,
                    },
                    lineWidthPlus: 1,
                    marker: {},
                    halo: false,
                    brightness: 0.1,
                    lineWidth: 2,
                },
                select: {
                    animation: {
                        duration: 0,
                    },
                    color: '#cccccc',
                    borderColor: '#000000',
                },
                inactive: {
                    animation: {
                        duration: 50,
                    },
                    opacity: 0.2,
                },
            },
            stickyTracking: true,
            turboThreshold: 1000,
            findNearestPointBy: 'x',
            borderRadius: 0,
            crisp: true,
            groupPadding: 0.2,
            pointPadding: 0.1,
            minPointLength: 0,
            startFromThreshold: true,
            tooltip: {
                distance: 6,
                pointFormat:
                    '<span style="color:{point.color}">●</span> <b> {series.name}</b><br/>Open: {point.open}<br/>High: {point.high}<br/>Low: {point.low}<br/>Close: {point.close}<br/>',
            },
            threshold: null,
            borderColor: '#ffffff',
            lineColor: '#000000',
            upColor: '#ffffff',
        },
        flags: {
            lineWidth: 1,
            allowPointSelect: false,
            showCheckbox: false,
            animation: {
                duration: 1000,
            },
            events: {},
            marker: null,
            point: {
                events: {},
            },
            dataLabels: {
                align: null,
                padding: 5,
                style: {
                    fontSize: '11px',
                    fontWeight: 'bold',
                    color: 'contrast',
                    textOutline: '1px contrast',
                },
                verticalAlign: null,
                x: 0,
                y: null,
            },
            cropThreshold: 50,
            opacity: 1,
            pointRange: 0,
            softThreshold: false,
            states: {
                normal: {
                    animation: true,
                },
                hover: {
                    animation: {
                        duration: 50,
                    },
                    lineWidthPlus: 1,
                    marker: {},
                    halo: false,
                    brightness: 0.1,
                    lineColor: '#000000',
                    fillColor: '#ccd6eb',
                },
                select: {
                    animation: {
                        duration: 0,
                    },
                    color: '#cccccc',
                    borderColor: '#000000',
                },
                inactive: {
                    animation: {
                        duration: 50,
                    },
                    opacity: 0.2,
                },
            },
            stickyTracking: false,
            turboThreshold: 1000,
            findNearestPointBy: 'x',
            borderRadius: 0,
            crisp: true,
            groupPadding: 0.2,
            pointPadding: 0.1,
            minPointLength: 0,
            startFromThreshold: true,
            tooltip: {
                distance: 6,
                pointFormat: '{point.text}<br/>',
            },
            threshold: null,
            borderColor: '#ffffff',
            allowOverlapX: false,
            shape: 'flag',
            stackDistance: 12,
            textAlign: 'center',
            y: -30,
            fillColor: '#ffffff',
            style: {
                fontSize: '11px',
                fontWeight: 'bold',
            },
        },
    },
    labels: {
        style: {
            position: 'absolute',
            color: '#333333',
        },
    },
    legend: {
        backgroundColor: 'none',
        borderColor: '#999999',
        borderRadius: 0,
        navigation: {
            activeColor: '#003399',
            inactiveColor: '#cccccc',
        },
        itemStyle: {
            color: '#333333',
            cursor: 'pointer',
            fontSize: '12px',
            fontWeight: 'bold',
            textOverflow: 'ellipsis',
        },
        itemHoverStyle: {
            color: '#000000',
        },
        itemHiddenStyle: {
            color: '#cccccc',
        },
        shadow: false,
        itemCheckboxStyle: {
            position: 'absolute',
            width: '13px',
            height: '13px',
        },
        squareSymbol: true,
        symbolPadding: 5,
        verticalAlign: 'bottom',
        x: 0,
        y: 0,
        title: {
            style: {
                fontWeight: 'bold',
            },
        },
    },
    tooltip: {
        enabled: true,
        animation: true,
        borderRadius: 3,
        dateTimeLabelFormats: {
            millisecond: '%A, %b %e, %H:%M:%S.%L',
            second: '%A, %b %e, %H:%M:%S',
            minute: '%A, %b %e, %H:%M',
            hour: '%A, %b %e, %H:%M',
            day: '%A, %b %e, %Y',
            week: 'Week from %A, %b %e, %Y',
            month: '%B %Y',
            year: '%Y',
        },
        footerFormat: '',
        padding: 8,
        snap: 10,
        headerFormat: '<span style="font-size: 10px">{point.key}</span><br/>',
        pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y}</b><br/>',
        backgroundColor: 'rgba(247,247,247,0.85)',
        borderWidth: 1,
        shadow: true,
        style: {
            color: '#333333',
            cursor: 'default',
            fontSize: '12px',
            whiteSpace: 'nowrap',
        },
    },
    credits: {
        enabled: true,
        href: 'https://www.highcharts.com?credits',
        position: {
            align: 'right',
            x: -10,
            verticalAlign: 'bottom',
            y: -5,
        },
        style: {
            cursor: 'pointer',
            color: '#999999',
            fontSize: '9px',
        },
        text: 'Highcharts.com',
    },
    scrollbar: {
        height: 14,
        barBorderRadius: 0,
        buttonBorderRadius: 0,
        margin: 10,
        minWidth: 6,
        step: 0.2,
        zIndex: 3,
        barBackgroundColor: '#cccccc',
        barBorderWidth: 1,
        barBorderColor: '#cccccc',
        buttonArrowColor: '#333333',
        buttonBackgroundColor: '#e6e6e6',
        buttonBorderColor: '#cccccc',
        buttonBorderWidth: 1,
        rifleColor: '#333333',
        trackBackgroundColor: '#f2f2f2',
        trackBorderColor: '#f2f2f2',
        trackBorderWidth: 1,
    },
    navigator: {
        height: 40,
        margin: 25,
        maskInside: true,
        handles: {
            width: 7,
            height: 15,
            symbols: ['navigator-handle', 'navigator-handle'],
            enabled: true,
            lineWidth: 1,
            backgroundColor: '#f2f2f2',
            borderColor: '#999999',
        },
        maskFill: 'rgba(102,133,194,0.3)',
        outlineColor: '#cccccc',
        outlineWidth: 1,
        series: {
            type: 'areaspline',
            fillOpacity: 0.05,
            lineWidth: 1,
            compare: null,
            dataGrouping: {
                approximation: 'average',
                enabled: true,
                groupPixelWidth: 2,
                smoothed: true,
                units: [
                    ['millisecond', [1, 2, 5, 10, 20, 25, 50, 100, 200, 500]],
                    ['second', [1, 2, 5, 10, 15, 30]],
                    ['minute', [1, 2, 5, 10, 15, 30]],
                    ['hour', [1, 2, 3, 4, 6, 8, 12]],
                    ['day', [1, 2, 3, 4]],
                    ['week', [1, 2, 3]],
                    ['month', [1, 3, 6]],
                    ['year', null],
                ],
            },
            dataLabels: {
                enabled: false,
                zIndex: 2,
            },
            id: 'highcharts-navigator-series',
            className: 'highcharts-navigator-series',
            lineColor: null,
            marker: {
                enabled: false,
            },
            threshold: null,
        },
        xAxis: {
            overscroll: 0,
            className: 'highcharts-navigator-xaxis',
            tickLength: 0,
            lineWidth: 0,
            gridLineColor: '#e6e6e6',
            gridLineWidth: 1,
            tickPixelInterval: 200,
            labels: {
                align: 'left',
                style: {
                    color: '#999999',
                },
                x: 3,
                y: -4,
            },
            crosshair: false,
        },
        yAxis: {
            className: 'highcharts-navigator-yaxis',
            gridLineWidth: 0,
            startOnTick: false,
            endOnTick: false,
            minPadding: 0.1,
            maxPadding: 0.1,
            labels: {
                enabled: false,
            },
            crosshair: false,
            title: {
                text: null,
            },
            tickLength: 0,
            tickWidth: 0,
        },
    },
    rangeSelector: {
        verticalAlign: 'top',
        buttonTheme: {
            fill: '#f7f7f7',
            stroke: '#333333',
            style: {
                color: '#333333',
            },
            states: {
                hover: {
                    fill: '#e6e6e6',
                    stroke: '#000000',
                    style: {
                        color: '#000000',
                    },
                },
                select: {
                    fill: '#e6ebf5',
                    stroke: '#000000',
                    style: {
                        color: '#000000',
                    },
                },
            },
        },
        floating: false,
        x: 0,
        y: 0,
        inputPosition: {
            align: 'right',
            x: 0,
            y: 0,
        },
        buttonPosition: {
            align: 'left',
            x: 0,
            y: 0,
        },
        labelStyle: {
            color: '#666666',
        },
    },
    navigation: {
        buttonOptions: {
            symbolStroke: '#333333',
            theme: {
                fill: '#f7f7f7',
            },
        },
    },
};

export const darkTheme = {
    colors: [
        '#2b908f',
        '#90ee7e',
        '#f45b5b',
        '#7798BF',
        '#aaeeee',
        '#ff0066',
        '#eeaaee',
        '#55BF3B',
        '#DF5353',
        '#7798BF',
        '#aaeeee',
    ],
    title: {
        style: {
            color: '#FFF',
        },
    },
    chart: {
        backgroundColor: {
            linearGradient: {x1: 0, y1: 0, x2: 1, y2: 1},
            stops: [
                [0, '#2a2a2b'],
                [1, '#3e3e40'],
            ],
        },
        style: {
            fontFamily: "'Unica One', sans-serif",
        },
        plotBorderColor: '#606063',
    },
    xAxis: {
        gridLineColor: '#707073',
        labels: {
            style: {
                color: '#E0E0E3',
            },
        },
        lineColor: '#707073',
        minorGridLineColor: '#505053',
        tickColor: '#707073',
        title: {
            style: {
                color: '#A0A0A3',
            },
        },
    },
    yAxis: {
        gridLineColor: '#707073',
        labels: {
            style: {
                color: '#E0E0E3',
            },
        },
        lineColor: '#707073',
        minorGridLineColor: '#505053',
        tickColor: '#707073',
        tickWidth: 1,
        title: {
            style: {
                color: '#A0A0A3',
            },
        },
    },
    tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        style: {
            color: '#F0F0F0',
        },
    },
    plotOptions: {
        series: {
            dataLabels: {
                color: '#F0F0F3',
                style: {
                    fontSize: '13px',
                },
            },
            marker: {
                lineColor: '#333',
            },
        },
        boxplot: {
            fillColor: '#505053',
        },
        candlestick: {
            lineColor: 'white',
        },
        errorbar: {
            color: 'white',
        },
    },
    legend: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        itemStyle: {
            color: '#E0E0E3',
        },
        itemHoverStyle: {
            color: '#FFF',
        },
        itemHiddenStyle: {
            color: '#606063',
        },
        title: {
            style: {
                color: '#C0C0C0',
            },
        },
    },
    credits: {
        style: {
            color: '#666',
        },
    },
    labels: {
        style: {
            color: '#707073',
        },
    },
    drilldown: {
        activeAxisLabelStyle: {
            color: '#F0F0F3',
        },
        activeDataLabelStyle: {
            color: '#F0F0F3',
        },
    },
    navigation: {
        buttonOptions: {
            symbolStroke: '#DDDDDD',
            theme: {
                fill: '#505053',
            },
        },
    },
    rangeSelector: {
        buttonTheme: {
            fill: '#505053',
            stroke: '#000000',
            style: {
                color: '#CCC',
            },
            states: {
                hover: {
                    fill: '#707073',
                    stroke: '#000000',
                    style: {
                        color: 'white',
                    },
                },
                select: {
                    fill: '#000003',
                    stroke: '#000000',
                    style: {
                        color: 'white',
                    },
                },
            },
        },
        inputBoxBorderColor: '#505053',
        inputStyle: {
            backgroundColor: '#333',
            color: 'silver',
        },
        labelStyle: {
            color: 'silver',
        },
    },
    navigator: {
        handles: {
            backgroundColor: '#666',
            borderColor: '#AAA',
        },
        outlineColor: '#CCC',
        maskFill: 'rgba(255,255,255,0.1)',
        series: {
            color: '#7798BF',
            lineColor: '#A6C7ED',
        },
        xAxis: {
            gridLineColor: '#505053',
        },
    },
    scrollbar: {
        barBackgroundColor: '#808083',
        barBorderColor: '#808083',
        buttonArrowColor: '#CCC',
        buttonBackgroundColor: '#606063',
        buttonBorderColor: '#606063',
        rifleColor: '#FFF',
        trackBackgroundColor: '#404043',
        trackBorderColor: '#404043',
    },
};
