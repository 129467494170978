import Highcharts from 'highcharts/highstock';
import React, {useEffect} from 'react';
import Exporting from 'highcharts/modules/exporting';
import styled from 'styled-components';
import {darkTheme, defaultTheme} from './themes';
import * as variables from '../../utils/variables';

const Wrapper = styled.div`
    margin: 8px;
    padding: 16px;
    width: 500px;
    height: 400px;
    border: 1px solid ${variables.tileBorders};
`;

Exporting(Highcharts);

Highcharts.createElement(
    'link',
    {
        href: 'https://fonts.googleapis.com/css?family=Unica+One',
        rel: 'stylesheet',
        type: 'text/css',
    },
    null,
    document.getElementsByTagName('head')[0],
);

Highcharts.setOptions({
    lang: {
        loading: 'Загрузка...',
        months: [
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Май',
            'Июнь',
            'Июль',
            'Август',
            'Сентябрь',
            'Октябрь',
            'Ноябрь',
            'Декабрь',
        ],
        weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
        shortMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
        exportButtonTitle: 'Экспорт',
        printButtonTitle: 'Печать',
        rangeSelectorFrom: 'С',
        rangeSelectorTo: 'По',
        rangeSelectorZoom: 'Период',
        downloadPNG: 'Скачать PNG',
        downloadJPEG: 'Скачать JPEG',
        downloadPDF: 'Скачать PDF',
        downloadSVG: 'Скачать SVG',
        printChart: 'Напечатать график',
        exitFullscreen: 'Вернуться',
        viewFullscreen: 'На весь экран',
    },
});
const timezone = new Date().getTimezoneOffset();

export const MultiHichart = props => {
    useEffect(() => {
        Highcharts.theme = props.darkTheme ? darkTheme : defaultTheme;

        Highcharts.setOptions(Highcharts.theme);
    }, []);

    useEffect(() => {
        Highcharts.chart(`chart${props.name}`, {
            tooltip: {
                valueDecimals: 2,
            },
            exporting: {
                sourceHeight: 700,
                sourceWidth: 1500,
            },
            title: {
                text: props.data[0].name,
            },
            xAxis: {
                type: 'datetime',
                ordinal: false,
                gridLineWidth: 1,
                dateTimeLabelFormats: {
                    millisecond: '%H:%M:%S.%L',
                    second: '%H:%M:%S',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '%Z день',
                    week: '%Z день',
                    month: "%b '%y",
                    year: '%Y',
                },
            },
            legend: {
                enabled: false,
            },
            series: props.data,
            time: {
                timezoneOffset: timezone,
            },
        });
    }, [props.data]);

    return <Wrapper id={`chart${props.name}`} />;
};
