import {Link} from 'react-router-dom';
import {Avatar, Button, Comment, Input, Tooltip, Form, Pagination} from 'antd';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Spinner} from '../../components/Atoms/Spinner';
import {ALARM_STATUS} from '../../commonConstants';

import {
    addAlarmComment,
    ALARM_COMMENTS_COUNT,
    deleteAlarmComment,
    getAlarmComments,
} from '../../reduxStore/modules/alarms';

const {TextArea} = Input;

const StyledComment = styled(Comment)`
    border-bottom: 1px solid #eee;

    .ant-comment-inner {
        padding: 10px 0;
    }
`;

const CommentsWrapper = styled.div`
    max-width: 560px;
`;

const Editor = ({onChange, onSubmit, submitting, value}) => (
    <div>
        <Form.Item>
            <TextArea rows={4} onChange={onChange} value={value} />
        </Form.Item>
        <Form.Item>
            <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
                Добавить комментарий
            </Button>
        </Form.Item>
    </div>
);

const CommentsBlockRaw = props => {
    const [currentPage, setCurrentPage] = useState(1);
    const [myComment, setComment] = useState();

    const fetchComments = () => {
        if (props.selectedAlarmId) {
            props.actions.getAlarmComments({id: props.selectedAlarmId, page: currentPage});
        }
    };

    useEffect(() => {
        fetchComments();
    }, [props.selectedAlarmId, currentPage]);

    const addComment = () => {
        if (myComment) {
            props.actions.addAlarmComment(props.selectedAlarmId, {message: myComment}).then(() => {
                props.actions.getAlarmComments({id: props.selectedAlarmId, page: currentPage});
                setComment('');
            });
        }
    };

    const handleChange = e => setComment(e.target.value);

    const deleteComment = id => {
        props.actions.deleteAlarmComment({id}).then(() => fetchComments());
    };

    const renderCommentText = comment => {
        if (comment.toState) {
            switch (comment.toState) {
                case ALARM_STATUS.OPEN:
                    return 'Создана тревога';
                case ALARM_STATUS.DOING:
                    return 'перевел в статус "В процессе"';
                default:
                    return 'Закрыто';
            }
        } else {
            return comment.text;
        }
    };
    const {user} = props;

    return props.comments ? (
        <CommentsWrapper>
            {props.comments.items.map(comment => (
                <StyledComment
                    author={<Link to="/">{`${comment.username}`}</Link>}
                    actions={[
                        <span onClick={() => deleteComment(comment.id)} key="deletecomments">
                            Удалить
                        </span>,
                    ]}
                    avatar={
                        <Avatar src={comment.avatar} alt={comment.username}>
                            {!comment.avatar && comment.username[0]}
                        </Avatar>
                    }
                    content={<p dangerouslySetInnerHTML={{__html: comment.message}} />}
                    datetime={
                        <Tooltip title={moment(comment.created).format('DD.MM.YYYY HH:mm:ss')}>
                            <span>{moment(comment.created).fromNow()}</span>
                        </Tooltip>
                    }
                />
            ))}
            <Comment
                avatar={
                    <Avatar src={user.avatar} alt={user.username}>
                        {!user.avatar && user.username[0]}
                    </Avatar>
                }
                content={<Editor onChange={handleChange} onSubmit={addComment} submitting={false} value={myComment} />}
            />
            <Pagination
                size="small"
                current={currentPage}
                total={props.comments.total}
                onChange={setCurrentPage}
                hideOnSinglePage
                pageSize={ALARM_COMMENTS_COUNT}
            />
        </CommentsWrapper>
    ) : (
        <Spinner />
    );
};

const mapStateToProps = state => ({
    comments: state.alarms.selectedAlarmComments.data,
    user: state.user.data,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getAlarmComments,
            deleteAlarmComment,
            addAlarmComment,
        },
        dispatch,
    ),
});

export const CommentsBlock = connect(mapStateToProps, mapDispatchToProps)(CommentsBlockRaw);
