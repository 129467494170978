export const departmentReducer = (state, action) => {
    switch (action.type) {
        case 'updateDepartments':
            return {
                ...state,
                departments: action.data,
            };
        case 'updateFarmgroups':
            return {
                ...state,
                farmgroups: action.data,
            };
        case 'updateFarms':
            return {
                ...state,
                farms: action.data,
            };
        default:
            return state;
    }
};
