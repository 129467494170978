import React from 'react';
import styled from 'styled-components';
import 'moment/locale/ru';

import {Form, Input, Tooltip, Icon, Select, Button} from 'antd';

const {Option} = Select;
const {TextArea} = Input;

const FormWrapper = styled.div`
    max-width: 600px;
    padding: 50px 20px;
`;

export const CreateAlarm = () => (
    <FormWrapper>
        <h2>Создание тревоги</h2>
        <AlarmForm />
    </FormWrapper>
);

const AlarmFormRaw = props => {
    const handleSubmit = e => {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
            }
        });
    };

    const {getFieldDecorator} = props.form;

    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 8},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 16},
        },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    return (
        <Form {...formItemLayout} onSubmit={handleSubmit}>
            <Form.Item label="Название">
                {getFieldDecorator('title', {
                    rules: [
                        {
                            required: true,
                            message: 'Необходимое поле',
                            whitespace: true,
                        },
                    ],
                })(<Input />)}
            </Form.Item>

            <Form.Item label={<span>Выберите площадку</span>}>
                {getFieldDecorator('department', {
                    rules: [{required: true, message: 'Необходимое поле', whitespace: true}],
                })(
                    <Select
                        showSearch
                        placeholder="Площадки"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Option value="jack">Пестречинка</Option>
                        <Option value="lucy">Казанская</Option>
                        <Option value="tom">Зеленодольская</Option>
                    </Select>,
                )}
            </Form.Item>

            <Form.Item label={<span>Выберите отделение</span>}>
                {getFieldDecorator('farmgroups', {
                    rules: [{required: true, message: 'Необходимое поле', whitespace: true}],
                })(
                    <Select
                        showSearch
                        placeholder="Отделения"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Option value="jack">Отделение 1</Option>
                        <Option value="lucy">Отделение 2</Option>
                        <Option value="tom">Отделение 3</Option>
                    </Select>,
                )}
            </Form.Item>

            <Form.Item label={<span>Выберите корпус</span>}>
                {getFieldDecorator('farms', {
                    rules: [{required: true, message: 'Необходимое поле', whitespace: true}],
                })(
                    <Select
                        showSearch
                        placeholder="Корпуса"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Option value="jack">Корпус 1</Option>
                        <Option value="lucy">Корпус 2</Option>
                        <Option value="tom">Корпус возле туалета на выходе</Option>
                    </Select>,
                )}
            </Form.Item>

            <Form.Item label="Описание" extra="Опишите описание тревоги, что вы сейчас описываете">
                {getFieldDecorator('description', {
                    rules: [
                        {
                            message: 'Please input your E-mail!',
                        },
                    ],
                })(<TextArea />)}
            </Form.Item>

            <Form.Item
                label={
                    <span>
                        Приоритет&nbsp;
                        <Tooltip title="Вы можете выбрать приоритет для соответствующего реагирования">
                            <Icon type="question-circle-o" />
                        </Tooltip>
                    </span>
                }
                extra="Вы можете выбрать приоритет для соответствующего реагирования"
            >
                {getFieldDecorator('priority')(
                    <Select placeholder="Отделения">
                        <Option value="jack">Критичный</Option>
                        <Option value="lucy">Нормальный</Option>
                        <Option value="tom">Малый</Option>
                    </Select>,
                )}
            </Form.Item>

            <Form.Item label="Ответственный человек">
                {getFieldDecorator('nickname')(
                    <Select
                        showSearch
                        placeholder="Сотрудники"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Option value="jack">Дядя Вася</Option>
                        <Option value="lucy">Дядя Ваня</Option>
                        <Option value="tom">Тетя Вася</Option>
                    </Select>,
                )}
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit">
                    Создать
                </Button>
            </Form.Item>
        </Form>
    );
};

const AlarmForm = Form.create({name: 'createAlarm'})(AlarmFormRaw);
