import {Button, Checkbox, Form, Modal, Spin} from 'antd';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';

import {getDefaultGraphicsValues} from 'commonConstants';
import {useStateValue} from '../../store/Provider';
import {fetcher} from '../../rest';
import variables from '../../utils/variables';

const MetricsListWrapper = styled.div`
    // border: 1px solid rgba(0, 0, 0, 0.2);
    // border-radius: 10px;
    padding: 10px;
    width: 100%;
    overflow-y: auto;
    margin-bottom: 16px;
    height: 400px;
`;

const PhoneWarning = styled.div`
    text-align: center;
    margin: auto;
    color: ${variables.red};

    @media screen and (min-width: ${variables.screenMd}) {
        display: none;
    }
`;

const CheckboxGroup = styled(Checkbox.Group)`
    display: flex;
    flex-direction: column;

    .ant-checkbox-group-item {
        margin-bottom: 10px;
    }
`;

export const MetricsListRaw = props => {
    const [dataList, setDataList] = useState([]);
    const [visible, setVisible] = useState(true);
    const [loading, setLoading] = useState(false);

    const [{farm}, dispatch] = useStateValue();

    const updateMetrics = data => {
        dispatch({
            type: 'updateMetrics',
            data,
        });
    };

    const getMetrics = (farmId, name, cycle) => {
        let query = '';
        if (cycle) {
            query = `?cycleId=${cycle}`;
        }

        return fetcher
            .get(`farms/${farmId}/metric/${name}${query}`)
            .then(data => ({
                // id: name,
                // name,
                name: props.data[name].label,
                data: data.series.map(el => [el.x, el.y]),
                // data: data.data.series.map(el => ({ x: new Date(el.x), y: el.y })),
            }))
            .catch(error => {
                console.log(error);
            });
    };

    useEffect(() => {
        const mappedArray = Object.keys(props.data).map(el => ({
            value: el,
            label: props.data[el].label,
        }));

        setDataList(sortBy(mappedArray, 'label'));

        return () => {
            updateMetrics([]);
        };
    }, []);

    const handleSubmit = e => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                setLoading(true);
                // @TODO  optimize query without already downloaded metrics
                let queryArray = [];

                if (Array.isArray(props.farmId)) {
                    props.farmId.forEach(el => {
                        queryArray = [
                            ...queryArray,
                            ...values.metrics.map(metric => getMetrics(el.farm.id, metric, el.cycle.id)),
                        ];
                    });
                } else {
                    queryArray = values.metrics.map(metric => getMetrics(props.farmId, metric, props.cycle));
                }
                Promise.all(queryArray)
                    .then(result => {
                        // debugger
                        updateMetrics(result);
                        setVisible(false);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.log(error);
                        setLoading(false);
                    });
            }
        });
    };

    const {getFieldDecorator} = props.form;

    return (
        <>
            <Button type="primary" onClick={() => setVisible(!visible)}>
                Изменить метрики
            </Button>
            <Modal title="Выберите метрики" visible={visible} footer={null} onCancel={() => setVisible(false)}>
                <PhoneWarning>Для удобства работы с графиками не советуем открывать их на телефоне</PhoneWarning>
                <Form layout="vertical" onSubmit={handleSubmit}>
                    <Spin tip="Загрузка..." spinning={loading}>
                        <MetricsListWrapper>
                            <Form.Item>
                                {getFieldDecorator('metrics', {
                                    rules: [{type: 'array', required: true, message: 'Нужно выбрать хотя бы одну'}],
                                    initialValue: getDefaultGraphicsValues(dataList),
                                })(<CheckboxGroup options={dataList} />)}
                            </Form.Item>
                        </MetricsListWrapper>

                        <Button type="primary" htmlType="submit" disabled={loading}>
                            Показать
                        </Button>
                    </Spin>
                </Form>
            </Modal>
        </>
    );
};

const mapStateToProps = state => ({
    cycle: state.house.cycle,
});

export const MetricsList = connect(mapStateToProps)(Form.create({name: 'register'})(MetricsListRaw));
