export const farmReducer = (state, action) => {
    switch (action.type) {
        case 'updateFarm':
            return {
                ...state,
                farm: action.data,
            };
        case 'updateCycleList':
            return {
                ...state,
                cycles: action.data,
            };
        case 'currentCycle':
            return {
                ...state,
                cycle: action.data,
            };
        case 'updateMetrics':
            return {
                ...state,
                metrics: action.data,
            };
        default:
            return state;
    }
};
