import React from 'react';
import {StyledMetric} from './StyledTile';

const METRICS_ON_CLOSED_CYCLE = [
    'temperature_in',
    'preset_temperature',
    'akbars_3_temperature_norm',
    'humidity',
    'akbars_airing',
];

export const MetricsList = props => {
    let showedMetrics = props.metrics;

    if (props.closed) {
        showedMetrics = props.metrics.filter(metric => METRICS_ON_CLOSED_CYCLE.includes(metric.key));
    }

    return (
        <>
            {showedMetrics.map(metric => {
                return (
                    metric.forFarmTile && (
                        <StyledMetric key={metric.key}>
                            {(() => {
                                switch (metric.key) {
                                    case 'temperature_in':
                                        const presetT = props.metrics.find(m => {
                                            return m.key === 'preset_temperature';
                                        });
                                        if (presetT) {
                                            return `${metric.label}: ${metric.value} (${presetT.value})`;
                                        }
                                    default:
                                        return `${metric.label}: ${metric.value}`;
                                }
                            })()}
                        </StyledMetric>
                    )
                );
            })}
        </>
    );
};
