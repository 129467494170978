import {combineReducers} from 'redux';

import {adminReducer as admin} from './admin';
import {houseReducers as house} from './house';
import {getAllUnitsReducer as units} from './units';
import {compareReducers as compare} from './compare';
import {alarmsReducers as alarms} from './alarms';
import {tasksReducers as tasks} from './tasks';
import {getUserInfoReducer as user} from './user';
import {getCompanyUsersReducer as users} from './users';
import {farmsReducer as farms} from './units/farms';
import {farmgroupsReducer as farmgroups} from './units/farmgroups';
import {departmentsReducer as departments} from './units/departments';

export const rootReducer = combineReducers({
    admin,
    house,
    units,
    compare,
    alarms,
    tasks,
    user,
    users,
    farms,
    farmgroups,
    departments,
});
