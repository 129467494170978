import {combineReducers} from 'redux';
import {createAction, handleActions} from 'redux-actions';
import {fetcher} from 'rest';

import {handleApiActions} from '../../utils';

const HOUSE_GET_FARM_INFO = 'HOUSE_GET_FARM_INFO';
const HOUSE_GET_METRICS = 'HOUSE_GET_METRICS';
const HOUSE_GET_CYCLES = 'HOUSE_GET_CYCLES';
const HOUSE_DELETE_CYCLES = 'HOUSE_DELETE_CYCLES';
const HOUSE_GET_ALARMS = 'HOUSE_GET_ALARMS';
const SET_SELECTED_CYCLE = 'SET_SELECTED_CYCLE';
const CLEAR_SELECTED_CYCLE = 'CLEAR_SELECTED_CYCLE';
const CLOSE_CYCLE = 'CLOSE_CYCLE';
const OPEN_CYCLE = 'OPEN_CYCLE';

const pagination = (size, offset) => {
    let pageSize = size;
    let pageOffset = offset;

    if (!size) {
        pageSize = 100;
    }
    if (!offset) {
        pageOffset = 0;
    }

    return `size=${pageSize}&offset=${pageOffset}`;
};

const initialCycle = '';

export const setSelectedCycle = createAction(SET_SELECTED_CYCLE);
export const clearSelectedCycle = createAction(CLEAR_SELECTED_CYCLE);

const selectCycleReducer = handleActions(
    {
        [SET_SELECTED_CYCLE]: (state, action) => action.payload,
        [CLEAR_SELECTED_CYCLE]: () => initialCycle,
    },
    initialCycle,
);

export const getFarmInfo = createAction(HOUSE_GET_FARM_INFO, farmId => fetcher.get(`/farms/${farmId}`));
export const getFarmInfoReducer = handleApiActions(HOUSE_GET_FARM_INFO);

export const getMetric = createAction(HOUSE_GET_METRICS, ({farmId, metricCode}) =>
    fetcher.get(`/farms?${farmId}/metric/${metricCode}`),
);
export const getMetricReducer = handleApiActions(HOUSE_GET_METRICS);

export const openCycle = createAction(OPEN_CYCLE, farmId => fetcher.post(`/farms/${farmId}/cycle/open`));
export const closeCycle = createAction(CLOSE_CYCLE, farmId => fetcher.post(`/farms/${farmId}/cycle/close`));

export const getFarmCycles = createAction(HOUSE_GET_CYCLES, (farmId, size, offset) =>
    fetcher.get(`/farms/${farmId}/cycles?sortBy=startDate&sortDir=DESC&${pagination(size, offset)}`),
);

export const deleteFarmCycles = createAction(HOUSE_DELETE_CYCLES, cycleIds =>
    fetcher.delete(`/farms/cycles`, {
        params: {
            cycleIds: `${cycleIds}`,
        },
    }),
);
export const getFarmCyclesReducer = handleApiActions(HOUSE_GET_CYCLES);

export const getFarmAlarms = createAction(HOUSE_GET_ALARMS, ({farmId, size, offset}) =>
    fetcher.get(`/alarms?farmId=${farmId}&${pagination(size, offset)}`),
);
export const getFarmAlarmsReducer = handleApiActions(HOUSE_GET_ALARMS);

export const houseReducers = combineReducers({
    cycle: selectCycleReducer,
    cycles: getFarmCyclesReducer,
    farmInfo: getFarmInfoReducer,
    metrics: getMetricReducer,
    alarms: getFarmAlarmsReducer,
});
