import Highcharts from 'highcharts/highstock';
import React, {useEffect} from 'react';
// Load the exporting module.
import Exporting from 'highcharts/modules/exporting';
import {darkTheme, defaultTheme} from './themes';
// Initialize exporting module.
Exporting(Highcharts);
const timezone = new Date().getTimezoneOffset()

Highcharts.dateFormats.W = function(timestamp) {
    const now = new Date(timestamp);
    const start = new Date(now.getFullYear(), 0, 0);
    const diff = now - start;
    const oneDay = 1000 * 60 * 60 * 24;
    const day = Math.floor(diff / oneDay);

    return day;
};

Highcharts.createElement(
    'link',
    {
        href: 'https://fonts.googleapis.com/css?family=Unica+One',
        rel: 'stylesheet',
        type: 'text/css',
    },
    null,
    document.getElementsByTagName('head')[0],
);

Highcharts.setOptions({
    lang: {
        loading: 'Загрузка...',
        months: [
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Май',
            'Июнь',
            'Июль',
            'Август',
            'Сентябрь',
            'Октябрь',
            'Ноябрь',
            'Декабрь',
        ],
        weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
        shortMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
        exportButtonTitle: 'Экспорт',
        printButtonTitle: 'Печать',
        rangeSelectorFrom: 'С',
        rangeSelectorTo: 'По',
        rangeSelectorZoom: 'Период',
        downloadPNG: 'Скачать PNG',
        downloadJPEG: 'Скачать JPEG',
        downloadPDF: 'Скачать PDF',
        downloadSVG: 'Скачать SVG',
        printChart: 'Напечатать график',
        exitFullscreen: 'Вернуться',
        viewFullscreen: 'На весь экран',
    },
});

export const Hichart = props => {
    useEffect(() => {
        Highcharts.theme = props.darkTheme ? darkTheme : defaultTheme;

        Highcharts.setOptions(Highcharts.theme);
    }, []);

    useEffect(() => {
        Highcharts.stockChart('chart', {
            tooltip: {
                valueDecimals: 2,
                footerFormat: false,
                formatter() {
                    // The first returned item is the header, subsequent items are the
                    // points
                    return [''].concat(
                        this.points
                            ? this.points.map(point => {
                                  return `${point.series.name}: ${point.y.toFixed(2)} <br> ${Highcharts.dateFormat(
                                      '%e/%b/%Y, %k:%M',
                                      new Date(point.series.userOptions.cycleStart + this.x),
                                  )}`;
                              })
                            : [],
                    );
                },
            },
            rangeSelector: {
                inputEnabled: false,
                allButtonsEnabled: true,
                buttons: [
                    {
                        type: 'day',
                        count: 3,
                        text: 'День',
                    },
                    {
                        type: 'week',
                        count: 1,
                        text: 'Неделя',
                    },
                    {
                        type: 'all',
                        text: 'Цикл',
                    },
                ],
                buttonTheme: {
                    width: 60,
                },
                selected: 2,
            },
            chart: {
                zoomType: 'x',
            },
            exporting: {
                sourceHeight: 700,
                sourceWidth: 1500,
            },
            title: {
                text: 'График',
            },
            xAxis: {
                type: 'datetime',
                ordinal: false,
                gridLineWidth: 1,
                dateTimeLabelFormats: {
                    millisecond: '%H:%M:%S.%L',
                    second: '%H:%M:%S',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '%W день',
                    week: '%W день',
                    month: "%b '%y",
                    year: '%Y',
                },
            },
            legend: {
                align: 'left',
                verticalAlign: 'bottom',
                borderWidth: 0,
                enabled: true,
            },
            series: props.data,
            time: {
                timezoneOffset: timezone,
            },
        });
    }, [props.data]);

    return (
        <>
            <div
                id="chart"
                style={{
                    padding: '16px 0',
                    width: '100%',
                    height: '700px',
                }}
            />
        </>
    );
};
