import find from 'lodash/find';

export const TOTAL_HEAD_OF_LIVESTOCK = {
    good: 90,
    normal: 60,
};

export const PAGES = {
    DEPARTMENT: 'departments',
    FARMGROUP: 'farmgroups',
    FARM: 'farms',
    ALARM: 'alarms',
    SIGNIN: 'auth',
    USER: 'user',
    CONTACTS: 'contacts',
    COMPARISON: 'compare',
    ADMIN: 'admin',
    USERS: 'users',
};

export const ALARM_STATUS = {
    DOING: 'in_proccess',
    DONE: 'resolved',
    OPEN: 'open',
};

export const TASK_STATUS = {
    DOING: 'in_progress',
    DONE: 'done',
    OPEN: 'open',
};

export const METRICS = {
    airing: {
        label: 'Проветривание',
        count: '',
    },
    chicken_livestock: {
        label: 'Поголовье кур',
        count: 'голов',
    },
    chicken_mortality: {
        label: 'Падеж кур',
        count: 'голов',
    },
    chicken_weight: {
        label: 'Вес курицы',
        count: 'кг',
    },
    cock_livestock: {
        label: 'Поголовье петухов',
        count: 'голов',
    },
    cock_mortality: {
        label: 'Падеж петухов',
        count: 'голов',
    },
    humidity: {
        label: 'Влажность',
        count: '',
    },
    temperature_in: {
        label: 'Температура внутри',
        count: 'С',
    },
    temperature_out: {
        label: 'Температуру снаружи',
        count: 'С',
    },
    total_food_consumption: {
        label: 'Потребление корма',
        count: 'кг',
    },
    total_water_consumption: {
        label: 'Потребление воды',
        count: 'литров',
    },
    performance: {
        label: 'Производительность',
        count: '',
    },
};

const defaultGraphicsValues = [
    'humidity',
    'temperature_out',
    'temperature_in',
    'chicken_mortality',
    'akbars_tunnel_leaf',
    'akbars_pressure',
];

const farmTypes = ['broiler', 'repair_young'];

export const getDefaultGraphicsValues = metricsList => {
    return defaultGraphicsValues.filter(el => {
        return find(metricsList, ['value', el]);
    });
};
