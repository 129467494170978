const variables = {
    // Colors
    brand: '#4F61A6',
    tileBorders: '#ddd',
    red: '#f4333c',

    // Gutters
    gutterXs: '8px',
    gutterSm: '16px',
    gutterMd: '24px',
    gutterLg: '32px',

    fontSizeBase: '16px',
    fontSizeSm: '14px',
    fontSizeXs: '12px',
    borderRadiusBase: '4px',
    borderRadiusBlocks: '8px',

    // Media
    screenMd: '768px',
    screenLg: '992px',
    screenXl: '1200px',
    screenXxl: '1600px',

    // Media Devices
    screenLaptop: '1366px',

    headerheight: '64px',
    siderWidth: '210px',
}

module.exports = variables
