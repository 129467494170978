import React, {useEffect, useState} from "react";
import find from "lodash/find";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {ActionType} from "redux-promise-middleware";
import {notification} from 'antd';

import {
    getAllowedUnitsByUserId,
    getUnitsByUserId,
    updateUserPermissions
} from "../../../reduxStore/modules/admin/users";
import {Spinner} from "../../../components/Atoms/Spinner";
import {CheckboxTree} from "./CheckboxTree";

const UserPageRaw = ({match, actions, availableUnits, allowedUnits, users}) => {
    const {userId} = match.params
    const [userName, setUserName] = useState('')


    useEffect(() => {
        actions.getUnitsByUserId({userId})
        actions.getAllowedUnitsByUserId({userId})

        const usersArray = users.data.items || []
        const userInfo = find(usersArray, {id: userId})
        if (userInfo) {
            setUserName(userInfo.fullName)
        }
    }, []);

    const submitAction = (accessibleFarmsIds) => {
        actions.updateUserPermissions({
            userId,
            accessibleFarmsIds
        })
            .then(() => {
                notification.success({
                    placement: 'bottomRight',
                    message: 'Чётко! Внатуре, чётко!',
                    description: 'Обновлены пермишены пользователя'
                })
            })
    }
    return availableUnits.status === ActionType.Fulfilled && allowedUnits.status === ActionType.Fulfilled ?
        <>
            <h2>{userName}</h2>
            <CheckboxTree
                data={availableUnits.data.items}
                allowedUnits={allowedUnits.data.accessibleFarmsIds}
                onSubmit={submitAction}
            />
        </>
        : <Spinner/>
}


const mapStateToProps = state => ({
    availableUnits: state.admin.availableUnits,
    allowedUnits: state.admin.allowedUnits,
    users: state.admin.users,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getUnitsByUserId,
            getAllowedUnitsByUserId,
            updateUserPermissions
        },
        dispatch,
    ),
});

export const UserPage = connect(mapStateToProps, mapDispatchToProps)(UserPageRaw);
