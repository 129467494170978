import React, {useState} from 'react';
import styled from 'styled-components';
import {Switch} from 'antd';
import {MetricsList} from './MetricsList';
import {useStateValue} from '../../store/Provider';
import {MultiHichart} from './MultiHighcharts';

const ControlPanel = styled.div`
    display: flex;
    justify-content: space-between;
`;

const GraphWrapper = styled.div``;
const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const MultiMetrics = props => {
    const [{farm}] = useStateValue();
    const [darkTheme, setDarkTheme] = useState(false);

    return (
        <GraphWrapper>
            <ControlPanel>
                <MetricsList key={props.cycle} data={props.data.state.metrics} farmId={props.data.id} />
                <span>
                    Темная тема <Switch onChange={setDarkTheme} checked={darkTheme} />
                </span>
            </ControlPanel>
            <br />
            <Wrapper>
                {farm.metrics &&
                    farm.metrics.map((metric, i) => {
                        return (
                            <MultiHichart
                                data={[metric]}
                                darkTheme={darkTheme}
                                name={i}
                                key={darkTheme ? `dark${i}` : `default${i}`}
                            />
                        );
                    })}
            </Wrapper>
        </GraphWrapper>
    );
};
