import {createAction} from 'redux-actions';
import {fetcher} from '../../../rest';
import {handleApiActions} from '../../utils';

const ADMIN_GET_UNITS_BY_USERID = 'ADMIN_GET_UNITS_BY_USERID';
const ADMIN_GET_ALLOWED_UNITS_BY_USERID = 'ADMIN_GET_ALLOWED_UNITS_BY_USERID';
const ADMIN_UPDATE_ALLOWED_UNITS = 'ADMIN_UPDATE_ALLOWED_UNITS';
const ADMIN_GET_USERS = 'ADMIN_GET_USERS';
const ADMIN_CREATE_USER = 'ADMIN_CREATE_USERS';
const ADMIN_UPDATE_USER = 'ADMIN_UPDATE_USERS';
const ADMIN_UPDATE_USER_PASS = 'ADMIN_UPDATE_USER_PASS';

export const pagination = (size, offset) => {
    let pageSize = size;
    let pageOffset = offset;

    if (!size) {
        pageSize = 100;
    }
    if (!offset) {
        pageOffset = 0;
    }

    return `size=${pageSize}&offset=${pageOffset}`;
};

export const getUnitsByUserId = createAction(ADMIN_GET_UNITS_BY_USERID, ({userId}) =>
    fetcher.get(`/v1/admin/user/company_structure?user_id=${userId}`),
);
export const getUnitsByUserIdReducer = handleApiActions(ADMIN_GET_UNITS_BY_USERID);

export const getAllowedUnitsByUserId = createAction(ADMIN_GET_ALLOWED_UNITS_BY_USERID, ({userId}) =>
    fetcher.get(`/v1/admin/user/farm_access?user_id=${userId}`),
);
export const getAllowedUnitsByUserIdReducer = handleApiActions(ADMIN_GET_ALLOWED_UNITS_BY_USERID);

export const getUsers = createAction(ADMIN_GET_USERS, ({company, size, offset}) =>
    fetcher.get(`/v1/admin/user?company_id=${company}&${pagination(size, offset)}`),
);
export const getUsersReducer = handleApiActions(ADMIN_GET_USERS);

export const updateUserPermissions = createAction(ADMIN_UPDATE_ALLOWED_UNITS, (data) =>
    fetcher.post(`/v1/admin/user/farm_access`, data),
);


export const createUser = createAction(ADMIN_CREATE_USER, data => fetcher.post('/users', data, {_handleError: true}));
export const createUserReducer = handleApiActions(ADMIN_CREATE_USER);

export const updateUser = createAction(ADMIN_UPDATE_USER, ({data, id}) => fetcher.patch(`/users/${id}`, data));
export const updateUserReducer = handleApiActions(ADMIN_UPDATE_USER);

export const updateUserPassword = createAction(ADMIN_UPDATE_USER_PASS, ({data, id}) =>
    fetcher.post(`/users/${id}/passwd`, data),
);
export const updateUserPasswordReducer = handleApiActions(ADMIN_UPDATE_USER_PASS);
