import {createAction} from 'redux-actions';
import {fetcher} from '../../../rest';
import {handleApiActions} from '../../utils';
import {pagination} from '../admin/users';

const GET_COMPANY_USERS = 'GET_COMPANY_USERS';

export const getCompanyUsers = createAction(GET_COMPANY_USERS, ({size, offset}) =>
    fetcher.get(`/users?sortBy=fullName&sortDir=ASC&${pagination(size, offset)}`),
);

export const getCompanyUsersReducer = handleApiActions(GET_COMPANY_USERS);
