import React, {useEffect} from 'react';
import {Spin, Typography} from 'antd';
import {ActionType} from 'redux-promise-middleware';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {getCompanyById} from '../../../reduxStore/modules/admin';
import {DepartmentList} from './DepartmentsList';

const {Title} = Typography;

const CompanyPageRaw = props => {
    const {company, actions} = props;

    useEffect(() => {
        actions.getCompanyById({id: props.match.params.companyId});
    }, []);

    return (
        <Spin spinning={ActionType.Fulfilled !== company.status}>
            <Title>{company?.data?.name || ''}</Title>
            <Title level={4}>Задержка: {company?.data?.dataRequestDelayInSeconds || ''}</Title>
            <DepartmentList />
        </Spin>
    );
};

const mapStateToProps = state => ({
    company: state.admin.company,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getCompanyById,
        },
        dispatch,
    ),
});

export const CompanyPage = connect(mapStateToProps, mapDispatchToProps)(CompanyPageRaw);
