import {createAction} from 'redux-actions';
import {fetcher} from 'rest';

import {handleApiActions, initialState} from '../../utils';
import sortBy from "lodash/sortBy";
import {priorityList} from "../../../utils";

const GET_FARMS_BY_FARMGROUP = 'GET_FARMS_BY_FARMGROUP';
const CLEAR_FARMS = 'CLEAR_FARMS';

export const getFarms = createAction(GET_FARMS_BY_FARMGROUP, id =>
    new Promise((res, rej) => {
        fetcher.get(`/farms?farmGroupId=${id}&sortBy=sortOrder`)
            .then(data => {
                data.items.map(dep => {
                    const mapped = Object.entries(dep.state.metrics).map(([el, val], index) => ({key: el, ...val}))
                    dep.state.metricsList = sortBy(mapped, val => priorityList(val))
                })
                res(data)
            })
            .catch(err => rej(err))
    })
);
export const clearFarms = createAction(CLEAR_FARMS);

export const farmsReducer = handleApiActions(
    GET_FARMS_BY_FARMGROUP,
    {
        [CLEAR_FARMS]: () => initialState,
    },
    initialState,
);
