import {combineReducers} from 'redux';
import {createAction} from 'redux-actions';
import {fetcher} from 'rest';

import {handleApiActions, initialState} from '../../utils';
import {TASK_STATUS} from '../../../commonConstants/constants';

export const TASK_COUNT = 20;

const GET_TASK = 'GET_TASK';
const CREATE_TASK = 'CREATE_TASK';
const UPDATE_TASK = 'UPDATE_TASK';
const GET_TASK_COMMENTS = 'GET_TASK_COMMENTS';
const DELETE_TASK_COMMENTS = 'DELETE_TASK_COMMENTS';
const TASKS_GET_BY_FARMID = 'TASKS_GET_BY_FARMID';
const CLEAR_TASKS = 'CLEAR_TASKS';
const RESOLVE_TASK = 'RESOLVE_TASK';
const UPDATE_TASK_STATE = 'UPDATE_TASK_STATE';
const ADD_TASK_COMMENT = 'ADD_TASK_COMMENT';

const pagination = (size, offset) => {
    let pageSize = size;
    let pageOffset = offset;

    if (!size) {
        pageSize = TASK_COUNT;
    }
    if (!offset) {
        pageOffset = 0;
    }

    return `size=${pageSize}&offset=${pageOffset}`;
};

const farmIdQueryParam = unitId => {
    return unitId ? `unitId=${unitId}&` : '';
};

export const getTasks = createAction(TASKS_GET_BY_FARMID, ({unitId, page}) =>
    fetcher.get(`/usertasks?${farmIdQueryParam(unitId)}${pagination(TASK_COUNT, (page - 1) * TASK_COUNT)}`),
);

export const clearTasks = createAction(CLEAR_TASKS);

const tasksReducer = handleApiActions(
    TASKS_GET_BY_FARMID,
    {
        [RESOLVE_TASK]: (state, action) => ({
            ...state,
            data: {
                ...state.data,
                items: state.data.items.map(el => {
                    const selectedEl = el;

                    if (el.id === action.payload) {
                        selectedEl.state = TASK_STATUS.DONE;
                    }
                    return selectedEl;
                }),
            },
        }),
        [CLEAR_TASKS]: () => initialState,
    },
    initialState,
);

export const getTask = createAction(GET_TASK, id => fetcher.get(`/usertasks/${id}`));

export const createTask = createAction(CREATE_TASK, data => fetcher.put(`/usertasks/`, data));

export const updateTask = createAction(UPDATE_TASK, (id, data) => fetcher.post(`/usertasks/${id}`, data));

export const getTaskReducer = handleApiActions(GET_TASK);

export const getTaskComments = createAction(GET_TASK_COMMENTS, ({id, page}) =>
    fetcher.get(
        `/usertasks/${id}/comments?sortBy=created&sortDir=DESC&${pagination(TASK_COUNT, (page - 1) * TASK_COUNT)}`,
    ),
);

export const addTaskComment = createAction(ADD_TASK_COMMENT, (id, data) =>
    fetcher.put(`/usertasks/${id}/comments`, data),
);

const updateTaskStatus = createAction(UPDATE_TASK_STATE, (id, state) =>
    fetcher.post(`/usertasks/${id}/state/${state}`),
);

export const resolveTask = id => updateTaskStatus(id, TASK_STATUS.DONE);
export const startTask = id => updateTaskStatus(id, TASK_STATUS.DOING);

export const getTaskCommentsReducer = handleApiActions(GET_TASK_COMMENTS);

export const deleteTaskComment = createAction(DELETE_TASK_COMMENTS, ({id}) => fetcher.delete(`/comments?id=${id}`));

export const deleteTaskCommentReducer = handleApiActions(DELETE_TASK_COMMENTS);

export const tasksReducers = combineReducers({
    list: tasksReducer,
    selected: getTaskReducer,
    selectedTaskComments: getTaskCommentsReducer,
});
