import {createAction} from 'redux-actions';
import {fetcher} from 'rest';

import {handleApiActions, initialState} from '../../utils';
import sortBy from 'lodash/sortBy';
import {priorityList} from "../../../utils";

const GET_FARMGROUPS_BY_DEPARTMENT = 'GET_FARMGROUPS_BY_DEPARTMENT';
const CLEAR_FARMGROUPS = 'CLEAR_FARMGROUPS';

export const getFarmgroups = createAction(GET_FARMGROUPS_BY_DEPARTMENT, id =>
    new Promise((res, rej) => {
        fetcher.get(`/farmgroups?departmentId=${id}&sortBy=sortOrder`)
            .then(data => {
                data.items.map(dep => {
                    const mapped = Object.entries(dep.state.metrics).map(([el, val], index) => ({key: el, ...val}))

                    dep.state.metricsList = sortBy(mapped, val => priorityList(val))
                })
                res(data)
            })
            .catch(err => rej(err))
    })
);
export const clearFarmgroups = createAction(CLEAR_FARMGROUPS);

export const farmgroupsReducer = handleApiActions(
    GET_FARMGROUPS_BY_DEPARTMENT,
    {
        [CLEAR_FARMGROUPS]: () => initialState,
    },
    initialState,
);
