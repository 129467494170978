import React, {useEffect, useState} from 'react';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
import {priorityList} from '../../utils';
import {StyledDesktopTable, StyledMobileTable} from "../Tile";

moment.locale('ru');

const mobileColumns = [
    {
        title: 'Метрика',
        dataIndex: 'label',
        key: 'name',
        align: 'right',
        width: 200,
    },
    {
        title: 'Значение',
        dataIndex: 'value',
        key: 'value',
        width: 200,
    },
]

const columns = [
    {
        title: 'Метрика',
        dataIndex: 'name',
        key: 'name',
        align: 'right',
        width: 200,
    },
    {
        title: 'Значение',
        dataIndex: 'value',
        key: 'value',
        width: 160,
    },
    {
        title: 'Метрика',
        dataIndex: 'name2',
        key: 'name3',
        align: 'right',
        width: 200,
    },
    {
        title: 'Значение',
        dataIndex: 'value2',
        key: 'value2',
        width: 160,
    },
];

export const FarmInformation = props => {
    const farm = props.data;
    const farmMetrics = farm.state.metrics;

    const [metrics, setMetrics] = useState([]);
    const [metricsForMobile, setMetricsForMobile] = useState([]);

    useEffect(() => {
        const dataSource = []
        const mapped = Object.entries(farmMetrics).map(([el, val], index) => ({key: el, ...val}))
        const sorted = [{label: 'День цикла', value: farm.cycleDay,}, {label: 'Последнее обновление', value:farm.state.updatedAt ? moment(farm.state.updatedAt).format('DD.MM.YYYY HH:mm:ss'): 'Ошибка'}, ... sortBy(mapped, val => priorityList(val))];

        for (let i = 0; i < sorted.length; i = i + 2) {
           dataSource.push({
               key: i,
               name: sorted[i].label,
               value: sorted[i].value,
               name2: sorted[i + 1]?.label,
               value2: sorted[i + 1]?.value,
           },)

        }
        setMetrics(dataSource);
        setMetricsForMobile(sorted);
    }, []);

    return (
        <div>
            <StyledDesktopTable showHeader={false} size={'small'} bordered={false} pagination={false} dataSource={metrics} columns={columns} />
            <StyledMobileTable showHeader={false} size={'small'} bordered={false} pagination={false} dataSource={metricsForMobile} columns={mobileColumns} />
        </div>
    );
};
