import axios from 'axios';
import Cookies from 'js-cookie';
import {Modal} from 'antd';
import includes from 'lodash/includes';
import endsWith from 'lodash/endsWith';
import {PAGES} from '../commonConstants';

export const production = '/api/';
export const local = 'https://cloudd.pro/api/';
export const BASENAME = '';

export const original = process.env.WEBPACK_MODE === 'production' ? production : local;
// const original = 'http://84.201.146.116:8082/api/';
export const fetcher = axios.create({
    baseURL: original,
    headers: {
        'Content-Type': 'application/json',
    },
});

fetcher.interceptors.request.use(config => {
    const cookiesToken = Cookies.get('authorization');
    if (cookiesToken) {
        config.headers.Authorization = `Bearer ${cookiesToken}`;
    }

    return config;
});

export const formRedirect = () => {
    let redirectParam = '';
    const currentPageIsSignIn = includes(window.location.href, `/${PAGES.SIGNIN}`);
    const currentPageIsDefaultPage = endsWith(window.location.href, window.location.host);

    if (!(currentPageIsSignIn || currentPageIsDefaultPage)) {
        redirectParam = `?redirect=${window.location.href}`;
    }
    window.location = `${BASENAME}/${PAGES.SIGNIN}${redirectParam}`; // @TODO get history
};

fetcher.interceptors.response.use(
    response => {
        // Do something with response data
        return response.data;
    },
    error => {
        let response = error.response;
        if (error?.response?.status === 401) {
            formRedirect();
        } else if (response.config._handleError) {
            // do nothing because error handling should be with call
        } else {
            if (!document.querySelector('.ant-modal-confirm-error')) {
                Modal.error({
                    title: 'Что-то пошло не так',
                    content: 'Пожалуйста, попробуйте позднее или свяжитесь с нашей службой поддержки.',
                });
            }
        }

        return Promise.reject(error);
    },
);

export const setToken = val => {
    Cookies.set('authorization', val, {expires: 7});
};
