import React, {useEffect, useState} from 'react';
import {Button, Modal, Pagination, Table, Typography} from 'antd';

import {useRouteMatch, withRouter} from 'react-router';
import isEmpty from 'lodash/isEmpty';
import {CreateDepartmentForm} from 'components/Forms/CreateDepartmentForm';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {createDepartment, getDepartmentsById, updateDepartment} from '../../../reduxStore/modules/admin';
import {ActionLink, HeaderPart, ListWrapper} from '../Styled';

const {Title} = Typography;

const DepartmentListRaw = props => {
    const [visibleModal, setVisability] = useState(false);
    const [editable, setEditable] = useState({});
    const {url} = useRouteMatch();

    const toogleModalState = () => {
        if (visibleModal) {
            setEditable({});
        }
        setVisability(!visibleModal);
    };

    const onEditClick = (e, record) => {
        e.stopPropagation();
        setEditable(record);
        toogleModalState();
    };

    const columns = [
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Действия',
            key: 'operation',
            fixed: 'right',
            width: 200,
            render: (text, record) => <ActionLink onClick={e => onEditClick(e, record)}>Изменить</ActionLink>,
        },
    ];

    const {departments, actions} = props;

    const fetchDepartments = () => {
        actions.getDepartmentsById({id: props.match.params.companyId});
    };

    useEffect(() => {
        fetchDepartments();
    }, []);

    const onRowClick = obj => {
        const check = url.slice(-1);
        let origin = url;

        if (check === '/') {
            origin = origin.substr(0, origin.length - 1); // use strict in routing
        }

        props.history.push(`${origin}/${obj.id}`);
    };

    const onSubmitForm = values => {
        if (isEmpty(editable)) {
            actions
                .createDepartment(values)
                .then(() => {
                    toogleModalState();
                    fetchDepartments();
                })
                .catch(err => {
                });
        } else {
            actions
                .updateDepartment({id: editable.id, data: values})
                .then(() => {
                    toogleModalState();
                    fetchDepartments();
                })
                .catch(err => {
                });
        }
    };

    return (
        <ListWrapper>
            <HeaderPart>
                <Title>Подразделения компании</Title>
                <Button type="primary" onClick={toogleModalState}>
                    Создать новое
                </Button>
                <Modal
                    visible={visibleModal}
                    title="Создание подразделения"
                    footer={null}
                    onCancel={toogleModalState}
                    destroyOnClose
                >
                    <CreateDepartmentForm
                        onSubmit={onSubmitForm}
                        initialValues={{...editable, companyId: props.match.params.companyId}}
                    />
                </Modal>
            </HeaderPart>
            <Table
                dataSource={departments?.data?.content}
                columns={columns}
                pagination={false}
                rowKey={record => `row-${record.id}`}
                onRow={record => ({
                    onClick: () => onRowClick(record),
                })}
            />
            {/*<Pagination onChange={onchange} defaultCurrent={1} total={50} />*/}
        </ListWrapper>
    );
};

const mapStateToProps = state => ({
    departments: state.admin.departments,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            createDepartment,
            updateDepartment,
            getDepartmentsById,
        },
        dispatch,
    ),
});

export const DepartmentList = withRouter(connect(mapStateToProps, mapDispatchToProps)(DepartmentListRaw));
