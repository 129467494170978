import {createAction} from 'redux-actions';
import {fetcher} from 'rest';

import {handleApiActions} from '../../utils';

const GET_ALL_UNITS = 'GET_ALL_UNITS';
const GET_CYCLES = 'GET_CYCLES';

// const sortByName = (a, b) => {
//     if (a.name < b.name) return -1;
//     if (a.name > b.name) return 1;
//     return 0;
// };
export const getAllUnits = createAction(GET_ALL_UNITS, () => {
    return new Promise((res, rej) => {
        fetcher
            .get(`/units`)
            .then(data => {
                const units = {
                    departments: [],
                    farmgroups: [],
                    farms: [],
                };

                data.items.forEach(department => {
                    units.departments.push(department.item);

                    department.children.forEach(farmgroup => {
                        units.farmgroups.push({
                            ...farmgroup.item,
                            parent: department.item.id,
                        });

                        farmgroup.children &&
                            farmgroup.children.forEach(farm => {
                                units.farms.push({
                                    ...farm.item,
                                    parent: farmgroup.item.id,
                                });
                            });
                    });
                });

                // units.departments.sort(sortByName);
                // units.farmgroups.sort(sortByName);
                // units.farms.sort(sortByName);
                res(units);
            })
            .catch(err => {});
    });
});

export const getCyclesByFarmId = createAction(GET_CYCLES, ({farmId}) => fetcher.get(`/farms?${farmId}`));
export const getCyclesByFarmIdReducer = handleApiActions(GET_CYCLES);

export const getAllUnitsReducer = handleApiActions(GET_ALL_UNITS);

const INIT_EXPORT = 'INIT_EXPORT';
const INIT_FARMGROUP_EXPORT = 'INIT_FARMGROUP_EXPORT';

export const exportCommand = createAction(INIT_EXPORT, id => fetcher.get(`/export/departments/${id}`));

export const exportCommandTest = createAction(INIT_EXPORT, id =>
    fetcher({
        url: `/export/departments/${id}`,
        method: 'GET',
        responseType: 'blob',
    }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'cloudd_export.xls');
        document.body.appendChild(link);
        link.click();
    }),
);

export const exportFarmgroupCommandTest = createAction(INIT_FARMGROUP_EXPORT, id =>
    fetcher({
        url: `/export/farmgroups/${id}`,
        method: 'GET',
        responseType: 'blob',
    }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'cloudd_export.xls');
        document.body.appendChild(link);
        link.click();
    }),
);
