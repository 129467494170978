import React, {useEffect} from 'react';
import {Spin, Typography} from 'antd';
import {ActionType} from 'redux-promise-middleware';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {getDepartmentById} from '../../../reduxStore/modules/admin';
import {FarmgroupList} from './FarmgroupsList';

const {Title} = Typography;

const DepartmentPageRaw = props => {
    const {department, actions} = props;

    useEffect(() => {
        actions.getDepartmentById({id: props.match.params.departmentId});
    }, []);

    return (
        <Spin spinning={ActionType.Fulfilled !== department.status}>
            <Title>{department?.data?.name || ''}</Title>
            <FarmgroupList />
        </Spin>
    );
};

const mapStateToProps = state => ({
    department: state.admin.department,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getDepartmentById,
        },
        dispatch,
    ),
});

export const DepartmentPage = connect(mapStateToProps, mapDispatchToProps)(DepartmentPageRaw);
