import React from 'react';
import {FarmTile, TilesWrapper} from 'components/Tile';
import {Redirect} from 'react-router';
import {PAGES} from '../../commonConstants';

const cycleProgress = farm => {
    const day = farm.cycleDay;
    const length = farm.cycleLength || 42;

    const progress = (100 * day) / length;
    return progress >= 100 ? 99 : progress;
};
export const FarmsList = ({data}) => (
    <TilesWrapper>
        {data.length === 1 && <Redirect to={`/${PAGES.FARM}/${data[0].id}`} />}
        {data.map(farm => (
            <FarmTile
                {...farm}
                title={farm.name}
                id={farm.id}
                // alert={farm.unresolvedAlarmCount}
                link={`/${PAGES.FARM}/${farm.id}/`}
                // currrentCount={Math.floor(Math.random() * 100)}
                // totalCount={100}
                // totalCount={farmgroup.state.metrics.metric.livestock_count}
                currentProgress={cycleProgress(farm)}
                key={farm.id}
            />
        ))}
    </TilesWrapper>
);
