import React from 'react';
import styled from 'styled-components';
import {Icon} from 'antd';

const CycleEl = styled.div`
    width: 250px;
    border-radius: 5px;
    background: white;
    padding: 10px;
    position: relative;
    margin-right: 8px;
    margin-bottom: 8px;
    border: 1px solid #001529;
`;

const DeleteButton = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
`;

export const CycleElement = props => (
    <CycleEl>
        <DeleteButton onClick={props.handleDelete}>
            <Icon type="close-circle" />
        </DeleteButton>
        <div>
            {props.department} {'>'}
        </div>
        <div>
            {props.farmgroup} {'>'}
        </div>
        <div>
            {props.farm} {'>'}
        </div>
        <div>{props.cycle}</div>
        <div>
            График: <b>{props.id}</b>
        </div>
    </CycleEl>
);
