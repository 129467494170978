import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Icon, notification, Typography} from 'antd';
import {getAlarm} from 'reduxStore/modules/alarms';
import {ActionType} from 'redux-promise-middleware';
import {Spinner} from 'components/Atoms/Spinner';
import {fetcher} from 'rest';
import find from 'lodash/find';
import {CommentsBlock} from './Comments';
import {AlarmContent, AlarmStatus, StyledAlarmPanel, StyledButton} from './StyledAlarm';
import {ALARM_STATUS} from '../../commonConstants';
import {resolveAlarmLocally} from '../../reduxStore/modules/alarms';
import {DepartmentLink, FarmgroupLink, FarmLink} from '../../components/Atoms/Links';

const {Text} = Typography;

const AlarmItemRaw = props => {
    const [alarmsDepartment, setAlarmsDepartment] = useState();
    const [alarmsFarmgroup, setAlarmsFarmgroup] = useState();
    const [alarmsFarm, setAlarmsFarm] = useState();

    useEffect(() => {
        if (props.selectedAlarmId) {
            props.actions.getAlarm(props.selectedAlarmId);
        }
    }, [props.selectedAlarmId]);

    useEffect(() => {
        if (props.units.status === ActionType.Fulfilled && props.alarm.status === ActionType.Fulfilled) {
            const farm = find(props.units.data.farms, ['id', props.alarm.data.unit.id]);
            const farmGroup = find(props.units.data.farmgroups, ['id', farm.parent]);
            const department = find(props.units.data.departments, ['id', farmGroup.parent]);

            setAlarmsFarm(farm);
            setAlarmsFarmgroup(farmGroup);
            setAlarmsDepartment(department);
        }
    }, [props.units, props.alarm]);

    const resolveAlarm = () => {
        fetcher.post(`/alarms/${props.selectedAlarmId}/resolve`).then(() => {
            props.actions.getAlarm(props.selectedAlarmId);
            props.actions.resolveAlarmLocally(props.selectedAlarmId);

            notification.success({
                placement: 'bottomRight',
                description: 'Тревога была обработана и больше не считается активной',
            });
        });
    };

    return props.selectedAlarmId && alarmsFarm && props.alarm.status === ActionType.Fulfilled ? (
        <AlarmContent>
            <h2>
                <AlarmStatus status={props.alarm.data.state} />
                {props.alarm.data.title}
            </h2>
            <StyledAlarmPanel>
                <Text strong>{moment(props.alarm.data.createdAt).format('DD.MM.YYYY HH:mm:ss')}</Text>
                <div>
                    {props.alarm.data.state === ALARM_STATUS.OPEN && (
                        <StyledButton htmlType="submit" onClick={resolveAlarm} type="primary" done>
                            Закрыть
                        </StyledButton>
                    )}
                    {/* {alarms.alarm.state === ALARM_STATUS.OPEN && ( */}
                    {/*    ( */}
                    {/*    <StyledButton */}
                    {/*        htmlType="submit" */}
                    {/*        onClick={() => console.log('lel')} */}
                    {/*        type="primary" */}
                    {/*        doing */}
                    {/*    > Взять в работу */}
                    {/*    </StyledButton> */}
                    {/* )} */}
                    {/* {(alarms.alarm.state === ALARM_STATUS.OPEN || alarms.alarm.state === ALARM_STATUS.DOING) && ( */}
                    {/*    <StyledButton */}
                    {/*        htmlType="submit" */}
                    {/*        onClick={() => console.log('lel')} */}
                    {/*        type="primary" */}
                    {/*        done */}
                    {/*    > Закрыть */}
                    {/*    </StyledButton> */}
                    {/* )} */}
                </div>
            </StyledAlarmPanel>
            <div>
                <DepartmentLink {...alarmsDepartment} /> <Icon type="right" />
                <FarmgroupLink {...alarmsFarmgroup} /> <Icon type="right" />
                <FarmLink {...alarmsFarm} />
            </div>
            {/* <StyledDescription> */}
            {/*    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus at dignissimos enim explicabo minus */}
            {/*    nostrum repudiandae rerum totam! Animi dolor dolorem illo iure laudantium, magni nulla perspiciatis unde */}
            {/*    vitae voluptatum. */}
            {/* </StyledDescription> */}
            <CommentsBlock selectedAlarmId={props.alarm.data.id} />
        </AlarmContent>
    ) : (
        <Spinner />
    );
};

const mapStateToProps = state => ({
    alarm: state.alarms.selected,
    units: state.units,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getAlarm,
            resolveAlarmLocally,
        },
        dispatch,
    ),
});

export const AlarmItem = connect(mapStateToProps, mapDispatchToProps)(AlarmItemRaw);
