import styled from 'styled-components';
import {Icon, Spin} from 'antd';
import React from 'react';

const antIcon = <Icon type="loading" style={{fontSize: 24}} spin />;

export const StyledSpinner = styled(Spin)`
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    display: block;
`;

// export const Spinner = () => <StyledSpinner indicator={antIcon} />;
export const Spinner = ({preload, ...props}) => {
    return (
        <StyledSpinner indicator={antIcon} {...props}>
            {props.preload ? !props.spinning && props.children : props.children}
        </StyledSpinner>
    );
};
