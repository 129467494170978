import React from 'react';
import styled from 'styled-components';
import {Button, Form, Input, Select} from 'antd';

const {Option} = Select;

const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const CreateUserFormRaw = props => {
    const formItemLayout = {
        labelCol: {span: 8},
        wrapperCol: {span: 12},
    };

    const handleSubmit = e => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                props.onSubmit(values);
            }
        });
    };

    const compareToFirstPassword = (rule, value, callback) => {
        const {form} = props;
        if (value && value !== form.getFieldValue('password')) {
            callback('Пароли должны совпадать!');
        } else {
            callback();
        }
    };
    const {getFieldDecorator} = props.form;
    return (
        <Form onSubmit={handleSubmit}>
            <Form.Item {...formItemLayout} label="Пароль">
                {getFieldDecorator('password', {
                    rules: [{required: true, message: 'Введите пароль!'}],
                })(<Input type="password" placeholder="Пароль" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="Повторите пароль">
                {getFieldDecorator('repeatPassword', {
                    rules: [
                        {required: true, message: 'Введите пароль ещё раз!'},
                        {
                            validator: compareToFirstPassword,
                        },
                    ],
                })(<Input type="password" placeholder="Пароль" />)}
            </Form.Item>
            <Footer>
                <Button type="primary" htmlType="submit">
                    Сохранить
                </Button>
            </Footer>
        </Form>
    );
};
export const ChangePasswordForm = Form.create({name: 'changePassword'})(CreateUserFormRaw);
