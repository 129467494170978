import {Button, Layout, Tree} from 'antd';
import React, {useEffect, useState} from "react";
import concat from "lodash/concat";
import xor from "lodash/xor";
import find from "lodash/find";
import reduce from "lodash/reduce";
import styled from "styled-components";

const StyledButtonWrapper = styled.div`
   padding-top: 24px;
`;
export const CheckboxTree = ({data, allowedUnits, onSubmit}) => {
    const [units, setUnits] = useState([])
    const [flattenData, setFlattenData] = useState([])
    const [checked, setChecked] = useState(allowedUnits)

    useEffect(() => {
        const sorted = data.map((department) => {
            return {
                title: department.name,
                key: department.id,
                children: department.descendants.map((farmgroup) => {
                    return {
                        title: farmgroup.name,
                        key: farmgroup.id,
                        children: farmgroup.descendants.map((farm) => {
                            return {
                                title: farm.name,
                                key: farm.id,
                            }
                        })
                    }
                })
            }
        })

        setUnits(sorted)

    }, [data]);

    useEffect(() => {
        if (data) {
            let departmentMap = []
            let farmgroupMap = []
            let farmMap = []

            data.forEach((department) => {
                departmentMap.push({
                    name: department.name,
                    id: department.id,
                    type: 'department'
                })
                department.descendants.forEach((farmgroup) => {
                    farmgroupMap.push({
                        name: farmgroup.name,
                        id: farmgroup.id,
                        departmentId: department.id,
                        type: 'farmgroup'
                    })
                    farmgroup.descendants.forEach((farm) => {
                        farmMap.push({
                            name: farm.name,
                            id: farm.id,
                            departmentId: department.id,
                            farmgroupId: farmgroup.id,
                            type: 'farm'
                        })
                    })
                })
            })

            setFlattenData(concat(departmentMap, farmgroupMap, farmMap))
        }

    }, [data]);

    const onCheck = (checkedKeys, info) => {
        console.log('onCheck', checkedKeys, info);

        setChecked(checkedKeys)
    };

    const onSelect = (checkedKeys, info) => {
        const selectedId = checkedKeys[0] || info.node.props.eventKey
        console.log('onSelect', selectedId, checkedKeys, info);
        if (info.node.props.selected) {
            setChecked(concat(checked, findAllLeafs(selectedId)))
        } else {
            setChecked(xor(checked, findAllLeafs(selectedId)))
        }
    };

    const findAllLeafs = (id) => {
        const selectedObject = find(flattenData, {id});

        if (selectedObject.type === 'farmgroup') {
            return reduce(flattenData, (res, el) => {
                return el.farmgroupId === selectedObject.id && concat(res, [el.id])
                    || res;
            }, []);
        } else if (selectedObject.type === 'department') {
            return reduce(flattenData, (res, el) => {
                return el.departmentId === selectedObject.id && concat(res, [el.id]) || res;
            }, []);
        } else {
            return [selectedObject.id];
        }
    }

    const prepareData = () => {
        return reduce(flattenData, (res, el) => {
            return find(checked,e => e === el.id) && el.type === 'farm' && concat(res, [el.id]) || res;
        }, [])
    }

    return units.length > 0 &&
        <>
            <Tree
                treeCheckable
                checkable
                defaultExpandAll
                checkedKeys={checked}
                onCheck={onCheck}
                onSelect={onSelect}
                treeData={units}
            />
            <StyledButtonWrapper>
                <Button type="primary" onClick={() => onSubmit(prepareData())}>Сохранить</Button>
            </StyledButtonWrapper>
        </>
};