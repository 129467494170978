import React from 'react';
import {Badge, Icon, Progress, Tooltip} from 'antd';
import {Link, useHistory} from 'react-router-dom';

import {TOTAL_HEAD_OF_LIVESTOCK, COLORS} from 'commonConstants';
import moment from 'moment';
import {
    StyledAlert,
    StyledCard,
    StyledClosedCycle,
    StyledMetric,
    StyledProgressWrapper,
    TileTitle,
    TileWrapper,
} from './StyledTile';
import {MetricsList} from './MetricsList';

window.moment = moment;
const checkLevel = percents => {
    let type;
    let color;

    if (percents > TOTAL_HEAD_OF_LIVESTOCK.good) {
        type = 'smile';
        color = COLORS.green;
    } else if (percents > TOTAL_HEAD_OF_LIVESTOCK.normal) {
        type = 'meh';
        color = COLORS.yellow;
    } else {
        type = 'frown';
        color = COLORS.red;
    }

    return (
        <Icon
            type={type}
            style={{
                color,
            }}
        />
    );
};

export const ClosedCycle = () => {
    return <StyledClosedCycle type="stop" />;
};

export const FarmTile = props => {
    const history = useHistory();

    return (
        <TileWrapper>
            {props.unresolvedAlarmCount && false ? ( // remove && false after auto alarm closing will be ready
                <Badge
                    count={props.unresolvedAlarmCount}
                    title={`${props.unresolvedAlarmCount} нерешенных тревог`}
                    onClick={() => {
                        history.push(`${props.link}alarms?state=OPEN`);
                    }}
                >
                    <InnerTile {...props} />
                </Badge>
            ) : (
                <InnerTile {...props} />
            )}
        </TileWrapper>
    );
};

const isOutdated = updatedAt => {
    return moment().diff(moment(updatedAt), 'minutes') > 30;
};

const UpdatedTime = ({updatedAt}) => {
    return isOutdated(updatedAt) ? (
        <span>Обновлено {moment(updatedAt).format('DD.MM.YYYY HH:mm:ss')}</span>
    ) : (
        <Tooltip title={moment(updatedAt).format('DD.MM.YYYY HH:mm:ss')}>
            <span>Обновлено {moment(updatedAt).fromNow()}</span>
        </Tooltip>
    );
};

const InnerTile = props => {
    return (
        <Link
            to={props.link}
            onClick={e => {
                e.stopPropagation();
            }}
        >
            <StyledCard
                // alert={props.unresolvedAlarmCount}
                title={
                    <TileTitle>
                        {!props.isCycleOpen && (
                            <Tooltip title="Цикл закрыт">
                                <ClosedCycle />{' '}
                            </Tooltip>
                        )}
                        {props.title}
                    </TileTitle>
                }
            >
                <div>
                    {/* {props.isCycleOpen ? ( */}
                    <>
                        <MetricsList metrics={props.state.metricsList} closed={!props.isCycleOpen} />
                        {props.isCycleOpen && (
                            <>
                                <StyledMetric>{`День цикла: ${props.cycleDay}`}</StyledMetric>
                                <StyledMetric>
                                    {props.cycleStartedAt &&
                                        `Цикл начался ${moment(props.cycleStartedAt).format('DD.MM.YYYY')}`}
                                </StyledMetric>
                                <StyledMetric>
                                    {props.currentProgress >= 0 && (
                                        <StyledProgressWrapper>
                                            <span>Ход цикла</span>
                                            <Progress
                                                percent={props.currentProgress}
                                                size="small"
                                                format={() => props.cycleDay}
                                            />
                                        </StyledProgressWrapper>
                                    )}
                                </StyledMetric>
                            </>
                        )}
                        <StyledMetric alert={isOutdated(props.state.updatedAt) || undefined}>
                            {props.state && props.state.updatedAt && <UpdatedTime updatedAt={props.state.updatedAt} />}
                        </StyledMetric>
                    </>
                    {/* ) : ( */}
                    {/*    <span>Цикл закрыт</span> */}
                    {/* )} */}

                    {/* <span>Поголовье: </span> */}
                    {/* <StyledValue> */}
                    {/*    {props.currrentCount}/{props.totalCount} */}
                    {/* </StyledValue> */}
                    {/* <span> ({percents}%) </span> */}
                    {/* {checkLevel(percents)} */}
                </div>
                {props.alertText && <StyledAlert>{props.alertText}</StyledAlert>}
            </StyledCard>
        </Link>
    );
};
