import styled from 'styled-components';
import {Card, Dropdown, Icon, Table} from 'antd';
import {COLORS} from '../../commonConstants';
import variables from '../../utils/variables';

export const TilesWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    @media screen and (min-width: ${variables.screenMd}) {
        justify-content: normal;
    }
`;

export const TileWrapper = styled.div`
    margin: 10px;
    position: relative;
`;

export const TileTitle = styled.div`
    color: ${variables.brand};
`;

export const StyledProgressWrapper = styled.div`
    margin-top: 20px;
`;

export const StyledInDev = styled.div`
    color: #ccc;
    font-size: 12px;
`;

export const StyledCard = styled(Card)`
    min-height: 200px;
    width: 280px;
    border: 2px solid ${variables.tileBorders};
    ${props => props.alert && `border-color: ${COLORS.red}`};
    border-radius: 10px;

    a {
        color: ${variables.brand};
    }

    &:hover {
        box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.2);
    }
`;

export const StyledValue = styled.span`
    font-weight: bold;
`;

export const StyledMetric = styled.div`
    font-size: ${variables.fontSizeXs};
    margin-bottom: 3px;
    color: ${props => (props.alert ? `${COLORS.red}` : 'inherit')};

    &:last-child: {
        margin: 0;
    }

    @media screen and (min-width: ${variables.screenMd}) {
        font-size: ${variables.fontSizeSm};
    }
`;

export const StyledAlert = styled.div`
    margin-top: 15px;
    color: ${COLORS.red};
`;

export const StyledClosedCycle = styled(Icon)`
    color: ${variables.red};
`;

export const StyledDropdown = styled(Dropdown)`
    position: absolute;
    right: 0px;
    top: 12px;
    z-index: 10;
    padding: 8px;
`;

export const StyledDesktopTable = styled(Table)`
  display: none;
  @media screen and (min-width: ${variables.screenLg}) {
    display: block;
  }
  
`

export const StyledMobileTable = styled(Table)`
  display: block;
  @media screen and (min-width: ${variables.screenLg}) {
    display: none;
  }
`