import {ActionType} from 'redux-promise-middleware';
import {handleActions} from 'redux-actions';

export const initialState = {
    data: null,
    error: false,
};

export const handleApiActions = (name, reducerMap) => {
    const {Pending, Fulfilled, Rejected} = ActionType;

    return handleActions(
        {
            [`${name}_${Pending}`]: state => ({...state, status: Pending}),
            [`${name}_${Fulfilled}`]: (state, action) => ({...state, status: Fulfilled, data: action.payload}),
            [`${name}_${Rejected}`]: (state, action) => ({...state, status: Rejected, error: action.payload}),
            ...reducerMap,
        },
        initialState,
    );
};
