import React, {useState} from 'react';
import styled from 'styled-components';
import {CycleSelect, CycleElement} from 'components/CycleSelect';
import {useStateValue} from 'store/Provider';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Switch} from 'antd';
import {MetricsListCompare} from '../../components/Metrics/MetricsListCompare';
import {Hichart} from '../../components/Metrics/HighchartsCompare';
import {deleteSelectedFarm} from '../../reduxStore/modules/compare';

const StyledWrapper = styled.div``;

const CyclePickerWrapper = styled.div``;

const CycleList = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 16px 0;
`;

const Graph = styled.div`
    padding: 16px 0;
`;

const ControlPanel = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ComparisonPageRaw = props => {
    const [{farm}] = useStateValue();
    const [darkTheme, setDarkTheme] = useState(false);

    const {selected} = props.compare;

    return (
        <StyledWrapper>
            <CyclePickerWrapper>
                <CycleList>
                    {selected.length > 0
                        ? selected.map((el, key) => (
                              <CycleElement
                                  handleDelete={() => {
                                      props.actions.deleteSelectedFarm(key);
                                  }}
                                  id={key}
                                  key={el.cycle.id}
                                  department={el.department.label}
                                  farmgroup={el.farmgroup.label}
                                  farm={el.farm.label}
                                  cycle={el.cycle.label}
                              />
                          ))
                        : 'Нет выбранных циклов'}
                </CycleList>
            </CyclePickerWrapper>
            <ControlPanel>
                <CycleSelect />
                {selected.length > 1 && (
                    <>
                        <MetricsListCompare farmId={props.compare.selected} />
                        <span>
                            Темная тема <Switch onChange={setDarkTheme} checked={darkTheme} />
                        </span>
                    </>
                )}
            </ControlPanel>
            {selected.length > 1 && (
                <Graph>
                    <Hichart data={farm.metrics} darkTheme={darkTheme} key={darkTheme ? 'dark' : 'default'} />
                </Graph>
            )}
        </StyledWrapper>
    );
};

const mapStateToProps = state => ({
    compare: state.compare,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            deleteSelectedFarm,
        },
        dispatch,
    ),
});

export const ComparisonPage = connect(mapStateToProps, mapDispatchToProps)(ComparisonPageRaw);
