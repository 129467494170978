import {combineReducers} from 'redux';
import {createAction} from 'redux-actions';
import {fetcher} from 'rest';

import {handleApiActions} from '../../utils';
import {
    getUsersReducer as users,
    getUnitsByUserIdReducer as availableUnits,
    getAllowedUnitsByUserIdReducer as allowedUnits,
} from './users';

const GET_COMPANIES = 'GET_COMPANIES';
const GET_COMPANY = 'GET_COMPANY';
const CREATE_COMPANY = 'CREATE_COMPANY';
const UPDATE_COMPANY = 'UPDATE_COMPANY';

const pagination = (size, offset) => {
    let pageSize = size;
    let pageOffset = offset;

    if (!size) {
        pageSize = 100;
    }
    if (!offset) {
        pageOffset = 0;
    }

    return `size=${pageSize}&offset=${pageOffset}`;
};

const paginationV1 = (size, offset) => {
    let pageSize = size;
    let pageOffset = offset;

    if (!size) {
        pageSize = 100;
    }
    if (!offset) {
        pageOffset = 0;
    }

    return `size=${pageSize}&page=${pageOffset}`;
};

// ///////////// COMPANIES /////////////////////////////

export const getCompanies = createAction(GET_COMPANIES, ({size, offset}) =>
    fetcher.get(`/v1/admin/company?${paginationV1(size, offset)}`),
);
export const getCompaniesReducer = handleApiActions(GET_COMPANIES);

export const createCompany = createAction(CREATE_COMPANY, data => fetcher.post('/companies', data));
export const createCompanyReducer = handleApiActions(CREATE_COMPANY);

export const updateCompany = createAction(UPDATE_COMPANY, ({data, id}) => fetcher.patch(`/companies/${id}`, data));
export const updateCompanyReducer = handleApiActions(UPDATE_COMPANY);

export const getCompanyById = createAction(GET_COMPANY, ({id}) => fetcher.get(`/companies/${id}`));
export const getCompanyByIdReducer = handleApiActions(GET_COMPANY);

// //////////// DEPARTMENTS /////////////////////////////////

const GET_DEPARTMENTS = 'GET_DEPARTMENTS';
const CREATE_DEPARTMENTS = 'CREATE_DEPARTMENTS';
const UPDATE_DEPARTMENTS = 'UPDATE_DEPARTMENTS';
const GET_DEPARTMENT = 'GET_DEPARTMENT';

export const getDepartmentsById = createAction(GET_DEPARTMENTS, ({id, size, offset}) =>
    fetcher.get(`/v1/admin/department?company_id=${id}&${pagination(size, offset)}`),
);
export const getDepartmentsByIdReducer = handleApiActions(GET_DEPARTMENTS);

export const createDepartment = createAction(CREATE_DEPARTMENTS, data => fetcher.post('/v1/admin/department', data));
export const createDepartmentReducer = handleApiActions(CREATE_DEPARTMENTS);

export const updateDepartment = createAction(UPDATE_DEPARTMENTS, ({data, id}) =>
    fetcher.post(`/v1/admin/department/${id}`, data),
);
export const updateDepartmentReducer = handleApiActions(UPDATE_DEPARTMENTS);

export const getDepartmentById = createAction(GET_DEPARTMENT, ({id}) => fetcher.get(`/v1/admin/department/${id}`));
export const getDepartmentByIdReducer = handleApiActions(GET_DEPARTMENT);

// //////////// FARMGROUPS  /////////////////////////////////

const GET_FARMGROUPS = 'GET_FARMGROUPS';
const CREATE_FARMGROUP = 'CREATE_FARMGROUP';
const UPDATE_FARMGROUP = 'UPDATE_DEPARTMENTS';

export const getFarmgroupsList = createAction(GET_FARMGROUPS, ({id, size, offset}) =>
    fetcher.get(`/v1/admin/farm_group?department_id=${id}&${pagination(size, offset)}`),
);
export const getFarmgroupsListReducer = handleApiActions(GET_FARMGROUPS);

export const createFarmgroup = createAction(CREATE_FARMGROUP, data => fetcher.post('/v1/admin/farm_group', data));
export const createFarmgroupReducer = handleApiActions(CREATE_FARMGROUP);

export const updateFarmgroup = createAction(UPDATE_FARMGROUP, ({data, id}) => fetcher.patch(`/v1/admin/farm_group/${id}`, data));
export const updateFarmgroupReducer = handleApiActions(UPDATE_FARMGROUP);

// //////////// FARMS  /////////////////////////////////

const ADMIN_GET_FARMS = 'ADMIN_GET_FARMS';
const ADMIN_CREATE_FARM = 'ADMIN_CREATE_FARM';
const ADMIN_UPDATE_FARM = 'ADMIN_UPDATE_FARM';

export const getFarmsList = createAction(ADMIN_GET_FARMS, ({id, size, offset}) =>
    fetcher.get(`/v1/admin/farm?farm_group_id=${id}&${pagination(size, offset)}`),
);
export const getFarmsListReducer = handleApiActions(ADMIN_GET_FARMS);

export const createFarm = createAction(ADMIN_CREATE_FARM, data => fetcher.post('/v1/admin/farm', data));
export const createFarmReducer = handleApiActions(ADMIN_CREATE_FARM);

export const updateFarm = createAction(ADMIN_UPDATE_FARM, ({data, id}) => fetcher.post(`/v1/admin/farm/${id}`, data));
export const updateFarmReducer = handleApiActions(ADMIN_UPDATE_FARM);

export const adminReducer = combineReducers({
    companies: getCompaniesReducer,
    company: getCompanyByIdReducer,
    departments: getDepartmentsByIdReducer,
    department: getDepartmentByIdReducer,
    farmgroups: getFarmgroupsListReducer,
    farms: getFarmsListReducer,
    users,
    availableUnits,
    allowedUnits,
});
