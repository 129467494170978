import {Layout, Icon} from 'antd';
import React, {useState} from 'react';
import styled from 'styled-components';
import {NavLink} from 'react-router-dom';
import {PAGES} from 'commonConstants';
import variables from 'utils/variables';
import {HeaderMenu} from './HeaderMenu';
import {ADMIN_ROLE} from "../../utils";

const {Sider} = Layout;

const StyledSider = styled(({opened, ...props}) => <Sider {...props} />)`
    overflow: auto;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    background: #fff;
    z-index: 99;
    transform: ${props => (props.opened ? 'translate(0)' : 'translate(-100%)')};
    transition: all 0.5s;
    @media screen and (min-width: ${variables.screenMd}) {
        transform: translate(0);
        min-width: ${variables.siderWidth};
    }
`;

const StyledMenu = styled.div`
    padding-top: 64px;
    min-height: 100%;
    border-right: 0;
    display: flex;
    flex-direction: column;
    background: #001529;
    color: white;
    align-items: center;

    @media screen and (min-width: ${variables.screenMd}) {
        align-items: stretch;
    }
`;

const StyledNavLink = styled(NavLink)`
    padding: 10px 15px;
    color: rgba(255, 255, 255, 0.65);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    line-height: 40px;
    cursor: ${props => (props.dev ? 'not-allowed' : 'pointer')};
    position: relative;

    & > i {
        margin-right: 10px;
    }

    &.active {
        background-color: #1890ff;
        color: white;
    }

    &:active,
    &:hover,
    &:focus {
        color: white;
    }
`;

const StyledHint = styled.div`
    position: absolute;
    bottom: 6px;
    line-height: normal;
    color: #7a7a7a;
    right: 0;
    left: 0;
    margin: auto;
    text-align: center;
    font-style: italic;
`;

const onClick = e => {
    e.preventDefault();
    e.stopPropagation();
};

export const SideNav = props => {
    const [isCollapsed, setCollapsed] = useState(false);
    const isAdmin = props?.user?.data?.userRole === ADMIN_ROLE;

    return (
        <StyledSider
            opened={props.opened}
            collapsed={isCollapsed}
            collapsedWidth="100%"
            width={variables.siderWidth}
            breakpoint="md"
            onBreakpoint={point => (point ? setCollapsed(true) : setCollapsed(false))}
        >
            <StyledMenu>
                <StyledNavLink exact to="/">
                    <Icon type="home" />
                    Главная страница
                </StyledNavLink>
                {props.units &&
                    props.units.departments.map(department => (
                        <StyledNavLink key={department.id} to={`/${PAGES.DEPARTMENT}/${department.id}`}>
                            <Icon type="appstore" />
                            {department.name}
                        </StyledNavLink>
                    ))}
                <StyledNavLink to={`/${PAGES.ALARM}`}>
                    <Icon type="notification" />
                    {/* <Badge count={5} title="Последняя тревога 13.04 12:07" offset={[15, 0]}> */}
                    Тревоги
                    {/* </Badge> */}
                </StyledNavLink>
                {/* <StyledNavLink dev onClick={onClick} to={`/${PAGES.FARMGROUP}`}><Icon type="laptop" />Технический долг <StyledHint>(В разработке)</StyledHint></StyledNavLink> */}
                <StyledNavLink to={`/${PAGES.COMPARISON}`}>
                    <Icon type="bar-chart" />
                    Сравнение
                </StyledNavLink>

                {props.opened && <HeaderMenu />}
                {isAdmin &&  <StyledNavLink exact to={`/${PAGES.ADMIN}`}>
                    <Icon type="appstore" />
                    Структура
                </StyledNavLink>}
                {isAdmin &&  <StyledNavLink to={`/${PAGES.ADMIN}/${PAGES.USERS}`}>
                    <Icon type="user" />
                    Юзеры
                </StyledNavLink>}
            </StyledMenu>
        </StyledSider>
    );
};
