import {Breadcrumb} from 'antd';
import React from 'react';
import 'moment/locale/ru';
import {Alarm as AlarmComponent} from 'containers/Alarm';
import {StyledContent} from './StyledAlarm';

export const Alarm = () => {
    return [
        <Breadcrumb style={{margin: '16px 0'}} key="major1">
            <Breadcrumb.Item key="br1">Тревоги</Breadcrumb.Item>
        </Breadcrumb>,
        <StyledContent key="major2">
            <AlarmComponent />
        </StyledContent>,
    ];
};
