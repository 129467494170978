import {Button, Layout, List, Select, Tag, Typography, Form} from 'antd';
import styled from 'styled-components';
import React from 'react';
import variables from 'utils/variables';
import {ALARM_STATUS, COLORS} from '../../commonConstants';

const {Text} = Typography;

const {Content} = Layout;

export const AlarmStatus = ({status}) => {
    let color;
    let text;

    switch (status) {
        case ALARM_STATUS.DONE:
            color = COLORS.green;
            text = 'Решено';
            break;
        case ALARM_STATUS.DOING:
            color = COLORS.yellow;
            text = 'В процессе';
            break;
        default:
            color = COLORS.red;
            text = 'Не решено';
    }
    return <Tag color={color}>{text}</Tag>;
};

export const StyledContent = styled(Content)`
    margin: 0;
    min-height: 280px;
`;

export const AlarmList = styled.div`
    width: 350px;
    flex: none;
    background: #fff;
    padding: 24px;
    margin-right: 40px;
    border: 1px solid #ccc;
`;

export const AlarmWrapper = styled.div`
    display: flex;
`;

export const AlarmContent = styled.div`
    background: #fff;
    padding: 24px;
    flex: 1 1 auto;
`;

export const StyledAlarmStatus = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    vertical-align: middle;
    display: inline-block;
    background: ${props => props.color || 'black'};
`;

export const StyledDescription = styled(Text)`
    margin-bottom: 40px;
    display: block;
`;

export const StyledAlarmPanel = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 10px 0 20px;
`;

export const StyledSelect = styled(Select)`
    width: 120px;
`;

export const StyledButton = styled(Button)`
    margin-right: 15px;
    border: none;
    ${props => props.doing && `background: ${COLORS.yellow}`};
    ${props => props.done && `background: ${COLORS.green}`};
`;

export const StyledListItem = styled(List.Item)`
    cursor: pointer;
    padding: 10px;

    &:hover {
        background: #d9dcdf;
    }
`;

export const AlarmFilterWrapper = styled.div`
    background: white;
    padding: 24px;
    ${props => !props.compact && 'margin-bottom: 40px'};
`;

export const StyledSearchItem = styled(Form.Item)`
    width: 100%;
    max-width: 850px;

    & > div {
        width: 100%;
    }
`;

export const StyledSortRow = styled.div`
    display: flex;
    gap: 24px;
    margin-top: 16px;
`;

export const StyledSortElement = styled.div`
    ${props => props.active && `color: ${variables.brand}`};

    &:last-child {
        margin-left: auto;
    }

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
`;
