import styled from 'styled-components';

export const ListWrapper = styled.div`
    margin 20px 0;
`;

export const ActionLink = styled.span`
    cursor: pointer;
    color: blue;
    margin-right: 16px;

    &:hover {
        color: #7a00ff;
        font-weight: bold;
    }
`;

export const HeaderPart = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
