import {List, Modal, Pagination} from 'antd';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Spinner} from 'components/Atoms/Spinner';
import {ActionType} from 'redux-promise-middleware';
import {clearAlarms, getAlarms} from 'reduxStore/modules/alarms';
import queryString from 'query-string';
import {useHistory, useLocation} from 'react-router-dom';
import debounce from 'lodash/debounce';
import {AlarmItem} from './AlarmItem';
import {AlarmFilterWrapper, AlarmList, AlarmStatus, AlarmWrapper, StyledListItem} from './StyledAlarm';
import {FilterForm} from './FilterForm';

export const AlarmRaw = props => {
    moment.locale('ru');
    const {alarms, units} = props;
    const history = useHistory();
    const location = useLocation();

    const initialValues = useMemo(() => queryString.parse(location.search, {arrayFormat: 'bracket'}), [
        location.search,
    ]);

    const [selectedAlarmId, setSelectAlarmId] = useState(initialValues.selectedAlarm);
    const [currentPage, setCurrentPage] = useState(initialValues.page || 1);
    const [requestBody, setRequestBody] = useState();

    useEffect(() => {
        if (props.id) {
            initialValues.farmIds = [props.id];
        }
        setRequestBody(initialValues);
    }, []);

    useEffect(() => {
        if (requestBody) {
            history.push({
                search: queryString.stringify(
                    {page: currentPage, selectedAlarm: selectedAlarmId, ...requestBody},
                    {arrayFormat: 'bracket'},
                ),
            });

            const {page, sortParam, sortIncDec, selectedAlarm, ...rest} = requestBody;

            props.actions
                .getAlarms({page: currentPage, sort: {sortParam, sortIncDec}}, rest)
                .then(data => {
                    if (!selectedAlarmId && data.value.content.length) {
                        setSelectAlarmId(data.value.content[0].id);
                    }
                })
                .catch(() => {
                    Modal.error({
                        title: 'Что-то пошло не так',
                        content: 'Пожалуйста, попробуйте позднее или свяжитесь с нашей службой поддержки.',
                    });
                });
        }
    }, [currentPage, requestBody]);

    useEffect(() => {
        history.push({
            search: queryString.stringify(
                {page: currentPage, selectedAlarm: selectedAlarmId, ...requestBody},
                {arrayFormat: 'bracket'},
            ),
        });
    }, [selectedAlarmId]);

    const onItemClick = id => {
        setSelectAlarmId(id);
    };

    const filterValue = value => {
        setRequestBody({
            ...value,
            departmentIds: value.departmentIds ? [value.departmentIds] : [],
            farmGroupIds: value.farmGroupIds ? [value.farmGroupIds] : [],
            farmIds: props.id || (value.farmIds ? [value.farmIds] : []),
        });
    };
    const debouncedFormChange = useCallback(debounce(filterValue, 500), []);

    return units.status === ActionType.Fulfilled ? (
        <>
            <AlarmFilterWrapper compact={props.compact}>
                <FilterForm
                    history={history}
                    initialValues={initialValues}
                    compact={props.compact}
                    onValuesChange={debouncedFormChange}
                />
            </AlarmFilterWrapper>
            {alarms.status === ActionType.Fulfilled ? (
                <AlarmWrapper>
                    <AlarmList>
                        <List
                            itemLayout="horizontal"
                            dataSource={alarms.data.content}
                            renderItem={item => (
                                <StyledListItem onClick={() => onItemClick(item.id)}>
                                    <List.Item.Meta
                                        title={item.title}
                                        description={
                                            <div>
                                                <AlarmStatus status={item.state} />
                                                {moment(item.createdAt).format('DD.MM.YYYY HH:mm:ss')}
                                            </div>
                                        }
                                    />
                                </StyledListItem>
                            )}
                        />
                        <Pagination
                            size="small"
                            current={currentPage}
                            total={alarms.data.totalElements}
                            onChange={setCurrentPage}
                            pageSize={20}
                        />
                    </AlarmList>
                    {selectedAlarmId && !!alarms.data.content.length && <AlarmItem selectedAlarmId={selectedAlarmId} />}
                </AlarmWrapper>
            ) : (
                <Spinner />
            )}
        </>
    ) : (
        <Spinner />
    );
};

const mapStateToProps = state => ({
    alarms: state.alarms.list,
    units: state.units,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getAlarms,
            clearAlarms,
        },
        dispatch,
    ),
});

export const Alarm = connect(mapStateToProps, mapDispatchToProps)(AlarmRaw);
