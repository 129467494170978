import {combineReducers} from 'redux';
import {createAction} from 'redux-actions';
import {fetcher} from 'rest';

import {handleApiActions, initialState} from '../../utils';
import {ALARM_STATUS} from '../../../commonConstants/constants';

export const ALARM_COMMENTS_COUNT = 20;

const GET_ALARM = 'GET_ALARM';
const GET_ALARM_COMMENTS = 'GET_ALARM_COMMENTS';
const DELETE_ALARM_COMMENTS = 'DELETE_ALARM_COMMENTS';
const ADD_ALARM_COMMENT = 'ADD_ALARM_COMMENT';
const ALARMS_GET_BY_FARMID = 'ALARMS_GET_BY_FARMID';
const CLEAR_ALARMS = 'CLEAR_ALARMS';
const RESOLVE_ALARM = 'RESOLVE_ALARM';

const pagination = (size, offset) => {
    let pageSize = size;
    let pageOffset = offset;

    if (!size) {
        pageSize = 20;
    }
    if (!offset) {
        pageOffset = 0;
    }

    return `size=${pageSize}&offset=${pageOffset}`;
};
const farmIdQueryParam = farmId => {
    return farmId ? `farmId=${farmId}` : '';
};
const sortQueryParam = sort => {
    return sort?.sortParam ? `&sort=${sort.sortParam},${sort.sortIncDec}` : '';
};

export const getAlarms = createAction(ALARMS_GET_BY_FARMID, ({farmId, page, sort}, requestBody) =>
    fetcher.post(`/v1/alarms?${farmIdQueryParam(farmId)}${sortQueryParam(sort)}&size=20&page=${page - 1}`, requestBody),
);

export const clearAlarms = createAction(CLEAR_ALARMS);
export const resolveAlarmLocally = createAction(RESOLVE_ALARM);

const alarmsReducer = handleApiActions(
    ALARMS_GET_BY_FARMID,
    {
        [RESOLVE_ALARM]: (state, action) => ({
            ...state,
            data: {
                ...state.data,
                items: state.data.items.map(el => {
                    const selectedEl = el;

                    if (el.id === action.payload) {
                        selectedEl.state = ALARM_STATUS.DONE;
                    }
                    return selectedEl;
                }),
            },
        }),
        [CLEAR_ALARMS]: () => initialState,
    },
    initialState,
);

export const getAlarm = createAction(GET_ALARM, id => fetcher.get(`/alarms/${id}`));

export const getAlarmReducer = handleApiActions(GET_ALARM);

export const getAlarmComments = createAction(GET_ALARM_COMMENTS, ({id, page}) =>
    fetcher.get(`/alarms/${id}/comments?${pagination(ALARM_COMMENTS_COUNT, (page - 1) * ALARM_COMMENTS_COUNT)}`),
);
export const getAlarmCommentsReducer = handleApiActions(GET_ALARM_COMMENTS);

export const addAlarmComment = createAction(ADD_ALARM_COMMENT, (id, data) =>
    fetcher.put(`/alarms/${id}/comments`, data),
);

export const deleteAlarmComment = createAction(DELETE_ALARM_COMMENTS, ({id}) => fetcher.delete(`/comments?id=${id}`));

export const deleteAlarmCommentReducer = handleApiActions(DELETE_ALARM_COMMENTS);

export const alarmsReducers = combineReducers({
    list: alarmsReducer,
    selected: getAlarmReducer,
    selectedAlarmComments: getAlarmCommentsReducer,
});
