import { userReducer } from './reducers/user';
import { departmentReducer } from './reducers/departments';
import { farmReducer } from './reducers/farm';
import { alarmReducer } from './reducers/alarms';
import { unitsReducer } from './reducers/units';

export const mainReducer = ({
    user, departments, farm, alarms, units,
}, action) => ({
    user: userReducer(user, action),
    departments: departmentReducer(departments, action),
    farm: farmReducer(farm, action),
    alarms: alarmReducer(alarms, action),
    units: unitsReducer(units, action),
});
