import React, {useState} from 'react';

import {Button, Form, Modal, Select} from 'antd';
import styled from 'styled-components';
import {cycleName} from 'utils';
import {connect} from 'react-redux';
import {ActionType} from 'redux-promise-middleware';
import {bindActionCreators} from 'redux';
import {setSelectedFarms} from 'reduxStore/modules/compare';
import {Spinner} from '../Atoms/Spinner';
import {fetcher} from '../../rest';
import {useStateValue} from '../../store/Provider';

const {Option} = Select;

function onChange(value) {
    console.log(`selected ${value}`);
}

function onBlur() {
    console.log('blur');
}

function onFocus() {
    console.log('focus');
}

function onSearch(val) {
    console.log('search:', val);
}

const StyledButton = styled(Button)`
    margin-top: 20px;
`;

const CycleSelectFormRaw = props => {
    const {visible, form, closeModal} = props;
    const {getFieldDecorator, getFieldValue, resetFields} = form;

    const [farmGroupList, setFarmGroupList] = useState([]);
    const [farmList, setFarmList] = useState([]);
    const [cycles, setCycles] = useState([]);
    const [{units}, dispatch] = useStateValue();

    const onDepartmentChange = departmentId => {
        setFarmGroupList(props.units.data.farmgroups.filter(el => el.parent === departmentId));
        resetFields(['farmgroup', 'farm', 'cycle']);
    };

    const onFarmGroupChange = farmGroupId => {
        setFarmList(props.units.data.farms.filter(el => el.parent === farmGroupId));
        resetFields(['farm', 'cycle']);
    };

    const onFarmChange = farmId => {
        fetcher
            .get(`farms/${farmId}/cycles`)
            .then(response => {
                setCycles(response.items);
                resetFields(['cycle']);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const handleCreate = () => {
        const {departments, farmgroups, farms} = props.units.data;

        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            console.log('Received values of form: ', values);

            props.actions.setSelectedFarms({
                ...values,
                department: {
                    label: departments.find(el => el.id === values.department).name,
                    id: values.department,
                },
                farmgroup: {
                    label: farmgroups.find(el => el.id === values.farmgroup).name,
                    id: values.farmgroup,
                },
                farm: {
                    label: farms.find(el => el.id === values.farm).name,
                    id: values.farm,
                },
                cycle: {
                    label: cycleName(cycles.find(el => el.id === values.cycle)),
                    id: values.cycle,
                },
            });

            // form.resetFields();
            form.setFields({
                cycle: {
                    value: '',
                },
            });
            closeModal();
        });
    };

    return (
        <Spinner spinning={props.units.status !== ActionType.Fulfilled} preload>
            <Modal
                visible={visible}
                title="Добавить цикл к сравнению"
                okText="Добавить"
                onCancel={closeModal}
                onOk={handleCreate}
            >
                <Form layout="vertical">
                    <Form.Item label="Подразделение:">
                        {getFieldDecorator('department', {
                            rules: [{required: true, message: 'Пожалуйста, укажите подразделение!'}],
                        })(
                            <Select
                                showSearch
                                style={{width: 300}}
                                placeholder="Выберите подразделение"
                                optionFilterProp="children"
                                onChange={onDepartmentChange}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {props?.units?.data?.departments?.map(dep => (
                                    <Option key={dep.id} value={dep.id}>
                                        {dep.name}
                                    </Option>
                                ))}
                            </Select>,
                        )}
                    </Form.Item>
                    <Form.Item label="Площадка:">
                        {getFieldDecorator('farmgroup', {
                            rules: [{required: true, message: 'Пожалуйста, укажите площадку!'}],
                        })(
                            <Select
                                allowClear
                                showSearch
                                style={{width: 300}}
                                placeholder="Выберите площадку"
                                disabled={!getFieldValue('department')}
                                optionFilterProp="children"
                                onChange={onFarmGroupChange}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {farmGroupList.map(farmGroup => (
                                    <Option key={farmGroup.id} value={farmGroup.id}>
                                        {farmGroup.name}
                                    </Option>
                                ))}
                            </Select>,
                        )}
                    </Form.Item>
                    <Form.Item label="Корпус:">
                        {getFieldDecorator('farm', {
                            rules: [{required: true, message: 'Пожалуйста, укажите корпус!'}],
                        })(
                            <Select
                                allowClear
                                showSearch
                                style={{width: 300}}
                                placeholder="Выберите корпус"
                                optionFilterProp="children"
                                disabled={!getFieldValue('farmgroup')}
                                onChange={onFarmChange}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {farmList.map(farm => (
                                    <Option key={farm.id} value={farm.id}>
                                        {farm.name}
                                    </Option>
                                ))}
                            </Select>,
                        )}
                    </Form.Item>
                    <Form.Item label="Цикл:">
                        {getFieldDecorator('cycle', {
                            rules: [{required: true, message: 'Пожалуйста, укажите цикл!'}],
                        })(
                            <Select
                                allowClear
                                showSearch
                                style={{width: 300}}
                                placeholder="Выберите цикл"
                                optionFilterProp="children"
                                disabled={!getFieldValue('farm')}
                                onChange={onChange}
                                onFocus={onFocus}
                                onBlur={onBlur}
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {cycles.map(el => (
                                    <Option key={el.id} value={el.id}>
                                        {cycleName(el)}
                                    </Option>
                                ))}
                            </Select>,
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        </Spinner>
    );
};

const mapStateToProps = state => ({
    units: state.units,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            setSelectedFarms,
        },
        dispatch,
    ),
});

export const CycleSelectForm = Form.create({name: 'form_in_modal'})(
    connect(mapStateToProps, mapDispatchToProps)(CycleSelectFormRaw),
);
