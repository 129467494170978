import {Layout, Breadcrumb} from 'antd';
import React, {useEffect} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {ActionType} from 'redux-promise-middleware';

import {Spinner} from 'components/Atoms/Spinner';

import {DepartmentsList} from 'containers/DepartmentsList';
import {exportCommand, exportCommandTest} from '../../reduxStore/modules/units';
import {clearDepartments, getDepartments} from '../../reduxStore/modules/units/departments';
import variables from '../../utils/variables';

const {Content} = Layout;

const StyledContent = styled(Content)`
    background: #fff;
    padding: 0;
    margin: 0;
    min-height: 280px;
    position: relative;

    @media screen and (min-width: ${variables.screenMd}) {
        padding: 24px;
    }
`;

const MainRaw = ({actions, companyName, departments}) => {
    useEffect(() => {
        actions.clearDepartments();

        actions.getDepartments();
    }, []);

    return (
        <>
            <Breadcrumb style={{margin: '16px 0'}} key="major1">
                <Breadcrumb.Item key="br1">{companyName}</Breadcrumb.Item>
            </Breadcrumb>
            {departments.status === ActionType.Fulfilled ? (
                <StyledContent>
                    <DepartmentsList data={departments.data.items} />
                </StyledContent>
            ) : (
                <Spinner />
            )}
        </>
    );
};

const mapStateToProps = state => ({
    departments: state.departments,
    companyName: state?.user?.data?.companyName,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            exportCommand,
            exportCommandTest,
            getDepartments,
            clearDepartments,
        },
        dispatch,
    ),
});

export const Main = connect(mapStateToProps, mapDispatchToProps)(MainRaw);
