import React from 'react';
import {Badge, Icon, Menu} from 'antd';
import {Link} from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import {StyledAlert, StyledCard, StyledDropdown, TileTitle, TileWrapper} from './StyledTile';
import {MetricsList} from './MetricsList';
import {ClosedCycle} from './FarmTile';

export const Tile = props => (
    <TileWrapper>
        {props.unresolvedAlarmCount && false ? ( // the same as https://github.com/GBcrimson/cock/blob/9bddb56ad50ed35fc070d44f6d4351c245875403/cloudd-dashboard-ui/src/components/Tile/FarmTile.jsx#L52
            <Badge count={props.unresolvedAlarmCount} title={`${props.unresolvedAlarmCount} нерешенных тревог`}>
                <InnerTile {...props} />
            </Badge>
        ) : (
            <InnerTile {...props} />
        )}
    </TileWrapper>
);

const InnerTile = props => {
    const isAllCyclesClosed = isEmpty(props.state.metrics);
    const handleMenuClick = (e, callback) => {
        e.domEvent.preventDefault();
        e.domEvent.stopPropagation();

        callback(); // make export event
    };

    const makeMenu = actions => {
        return (
            <Menu>
                {actions.map(el => (
                    <Menu.Item key={el.id} onClick={e => handleMenuClick(e, el.callback)}>
                        {el.icon} {el.name}
                    </Menu.Item>
                ))}
            </Menu>
        );
    };

    return (
        <Link to={props.link}>
            {props.actions && (
                <StyledDropdown overlay={makeMenu(props.actions)} trigger={['click']}>
                    <Icon type="more" />
                </StyledDropdown>
            )}
            <StyledCard
                alert={props.alert}
                title={
                    <TileTitle>
                        {isAllCyclesClosed && <ClosedCycle />} {props.title}
                    </TileTitle>
                }
            >
                <div>
                    {isAllCyclesClosed ? <span>Закрыты все циклы</span> : <MetricsList metrics={props.state.metricsList} />}
                </div>
                {props.alertText && <StyledAlert>{props.alertText}</StyledAlert>}
            </StyledCard>
        </Link>
    );
};
