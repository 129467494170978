import React, {useState} from 'react';
import {Form, Icon, Input, Button} from 'antd';
import styled from 'styled-components';
import axios from 'axios';
import queryString from 'query-string';

import {original, setToken} from '../../rest';

const FormWrappwer = styled.div`
    max-width: 400px;
    margin: auto;
    padding: 50px;
    position: relative;
`;

const ErrorMessage = styled.div`
    color: red;
    text-align: center;
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
`;

export const SignIn = props => (
    <FormWrappwer>
        <WrappedNormalLoginForm {...props} />
    </FormWrappwer>
);
const NormalLoginForm = ({form, history}) => {
    const StyledButton = styled(Button)`
        width: 100%;
    `;
    const [error, setError] = useState(false);

    const handleSubmit = e => {
        e.preventDefault();
        setError(false);

        form.validateFields((err, values) => {
            if (!err) {
                axios
                    .post(
                        `${original}auth`,
                        {
                            ...values,
                            username: values.username.toLowerCase().trim(),
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        },
                    )
                    .then(data => {
                        setToken(data.data.token);
                        if (history.location.search) {
                            const params = queryString.parse(history.location.search);

                            const parsedURL = new URL(params.redirect);
                            const isValid =
                                parsedURL.host === window.location.host &&
                                parsedURL.protocol === window.location.protocol;

                            if (isValid) {
                                window.location.href = params.redirect;
                            } else {
                                history.push('/');
                            }
                        } else {
                            history.push('/');
                        }
                    })
                    .catch((err) => {
                        if (err?.response?.data?.error_text) {
                            setError(err.response.data.error_text);
                        } else {
                            setError('Неправильный логин или пароль');
                        }
                    });
            }
        });
    };

    const {getFieldDecorator} = form;
    return (
        <Form onSubmit={handleSubmit}>
            <Form.Item>
                {getFieldDecorator('username', {
                    rules: [{required: true, message: 'Пожалуйста, введите логин'}],
                })(<Input prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}} />} placeholder="Логин" />)}
            </Form.Item>
            <Form.Item>
                {getFieldDecorator('password', {
                    rules: [{required: true, message: 'Пожалуйста, введите пароль'}],
                })(
                    <Input
                        prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}} />}
                        type="password"
                        placeholder="Пароль"
                    />,
                )}
            </Form.Item>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <Form.Item>
                <StyledButton type="primary" htmlType="submit" className="login-form-button">
                    Войти
                </StyledButton>
            </Form.Item>
        </Form>
    );
};

const WrappedNormalLoginForm = Form.create({name: 'normal_login'})(NormalLoginForm);