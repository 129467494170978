import {Form, Input, Select, Row, Col, Icon} from 'antd';
import React, { useCallback, useEffect, useRef, useState } from "react";
import {connect} from 'react-redux';
import debounce from 'lodash/debounce';
import {StyledSearchItem, StyledSortElement, StyledSortRow} from './StyledAlarm';

const FilterFormRaw = ({form, onValuesChange, compact, initialValues, history, ...props}) => {
    const {getFieldDecorator, getFieldValue, resetFields} = form;
    const didMount = useRef(false);

    const [farmGroupList, setFarmGroupList] = useState(props.farmsData.farmgroups);
    const [farmList, setFarmList] = useState(props.farmsData.farms);
    const [sortOrder, setSortOrder] = useState(initialValues.sortIncDec || 'desc');
    const [sortName, setSortName] = useState(initialValues.sortParam || 'createdAt');

    const {Option} = Select;

    const onDepartmentChange = departmentId => {
        setFarmGroupList(props.farmsData.farmgroups.filter(el => el.parent === departmentId));
        resetFields(['farmGroupIds', 'farmIds']);
        handleChangeFields();
    };

    const onFarmGroupChange = farmGroupId => {
        setFarmList(props.farmsData.farms.filter(el => el.parent === farmGroupId));
        resetFields(['farmIds']);
        handleChangeFields();
    };

    const handleSubmit = () => {
        form.validateFields(['matchText', 'state', 'departmentIds', 'farmGroupIds', 'farmIds'], (err, values) => {
            if (!err) {
                onValuesChange({...values, sortParam: sortName, sortIncDec: sortOrder});
            }
        });
    };

    const handleClickSort = key => {
        if (sortName === key) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortName(key);
            setSortOrder('desc');
        }
    };

    const debouncedTyping = useCallback(
      debounce(handleSubmit, 500)
      , [sortName, sortOrder]);

    const handleChangeFields = () => {
        debouncedTyping(); // more convenient make it through form submitting however now ant deprecate this call "form.submit()"
    }

    const clearForm = () => {
        history.push({
            search: ''
        });        onValuesChange({});
        form.resetFields();
        setSortOrder('desc');
        setSortName('createdAt');

    }

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;
            return;
        }

        if (sortName || sortOrder) {
            handleSubmit();
        }
    }, [sortName, sortOrder]);

    return (
        <>
            <Form layout="inline" onSubmit={debouncedTyping}>
                <Row>
                    <Col>
                        <StyledSearchItem>
                                {getFieldDecorator('matchText', {
                                initialValue: initialValues?.matchText || '',
                                onChange: handleChangeFields,
                            })(<Input placeholder="Поиск" />)}
                        </StyledSearchItem>
                    </Col>
                </Row>
                <Row style={{margin: '15px 0'}}>
                    <Col>
                        <Form.Item>
                            {getFieldDecorator('state', {
                                initialValue: initialValues?.state,
                                onChange: handleChangeFields,
                            })(
                                <Select style={{width: 200}} placeholder="Состояние">
                                    <Option value={null}>Все</Option>
                                    <Option value="OPEN">Открытые</Option>
                                    <Option value="RESOLVED">Решенные</Option>
                                </Select>,
                            )}
                        </Form.Item>
                        {!compact && (
                            <>
                                <Form.Item>
                                    {getFieldDecorator('departmentIds', {
                                        initialValue: initialValues?.departmentIds?.[0],
                                    })(
                                        <Select
                                            allowClear
                                            showSearch
                                            style={{width: 200}}
                                            placeholder="Подразделение"
                                            optionFilterProp="children"
                                            onChange={onDepartmentChange}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {props?.farmsData?.departments?.map(dep => (
                                                <Option key={dep.id} value={dep.id}>
                                                    {dep.name}
                                                </Option>
                                            ))}
                                        </Select>,
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    {getFieldDecorator('farmGroupIds', {
                                        initialValue: initialValues?.farmGroupIds?.[0],
                                    })(
                                        <Select
                                            allowClear
                                            showSearch
                                            style={{width: 200}}
                                            placeholder="Площадка"
                                            disabled={!getFieldValue('departmentIds')}
                                            optionFilterProp="children"
                                            onChange={onFarmGroupChange}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {farmGroupList.map(farmGroup => (
                                                <Option key={farmGroup.id} value={farmGroup.id}>
                                                    {farmGroup.name}
                                                </Option>
                                            ))}
                                        </Select>,
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    {getFieldDecorator('farmIds', {
                                        initialValue: initialValues?.farmIds?.[0],
                                        onChange: handleChangeFields,
                                    })(
                                        <Select
                                            allowClear
                                            showSearch
                                            style={{width: 200}}
                                            placeholder="Корпус"
                                            optionFilterProp="children"
                                            disabled={!getFieldValue('farmGroupIds')}
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {farmList.map(farm => (
                                                <Option key={farm.id} value={farm.id}>
                                                    {farm.name}
                                                </Option>
                                            ))}
                                        </Select>,
                                    )}
                                </Form.Item>
                            </>
                        )}
                    </Col>
                </Row>
                <StyledSortRow>
                    <StyledSortElement active={sortName === 'createdAt'} onClick={() => handleClickSort('createdAt')}>
                        По дате{' '}
                        {sortName === 'createdAt' && (
                            <Icon type={sortOrder === 'asc' ? 'sort-ascending' : 'sort-descending'} />
                        )}
                    </StyledSortElement>
                    <StyledSortElement active={sortName === 'title'} onClick={() => handleClickSort('title')}>
                        По названию{' '}
                        {sortName === 'title' && (
                            <Icon type={sortOrder === 'asc' ? 'sort-ascending' : 'sort-descending'} />
                        )}
                    </StyledSortElement>
                    <StyledSortElement onClick={clearForm}>
                        Очистить
                    </StyledSortElement>
                </StyledSortRow>
            </Form>
        </>
    );
};

const mapStateToProps = state => ({
    farmsData: state.units.data,
});

export const FilterForm = Form.create({name: 'filter'})(connect(mapStateToProps)(FilterFormRaw));
