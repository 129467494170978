import {Breadcrumb} from 'antd';
import React from 'react';
import {connect} from 'react-redux';
import {ActionType} from 'redux-promise-middleware';
import {DepartmentLink, FarmgroupLink, FarmLink} from '../../components/Atoms/Links';

const BreadcrumbsFarmRaw = props => {
    const {farm} = props;
    const farmgroup = props.farm.data.parent;
    const department = props.farm.data.parent.parent;

    if (farm.status !== ActionType.Fulfilled) {
        return null;
    }

    return (
        <Breadcrumb style={{margin: '16px 0'}} key="major1">
            <Breadcrumb.Item>
                <DepartmentLink {...department} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <FarmgroupLink {...farmgroup} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <FarmLink {...farm.data} />
            </Breadcrumb.Item>
        </Breadcrumb>
    );
};

const mapStateToProps = state => ({
    farm: state.house.farmInfo,
});

export const BreadcrumbsFarm = connect(mapStateToProps)(BreadcrumbsFarmRaw);
