import React from 'react';
import {Tile, TilesWrapper} from 'components/Tile';
import {Icon} from 'antd';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {PAGES} from '../../commonConstants/constants';
import {exportCommand, exportFarmgroupCommandTest} from '../../reduxStore/modules/units';
import {Redirect} from "react-router";

export const FarmgroupsListRaw = ({data, actions}) => (
    <TilesWrapper>
        {data.length === 1 && <Redirect to={`/${PAGES.FARMGROUP}/${data[0].id}`} />}
        {data.map(farmgroup => (
            <Tile
                title={farmgroup.name}
                id={farmgroup.id}
                link={`/${PAGES.FARMGROUP}/${farmgroup.id}/`}
                state={farmgroup.state}
                key={farmgroup.id}
                actions={[
                    {
                        name: 'Выгрузить',
                        value: 'export',
                        id: farmgroup.id,
                        icon: <Icon type="download" />,
                        callback: () => {
                            actions.exportFarmgroupCommandTest(farmgroup.id);
                        },
                    },
                ]}
            />
        ))}
    </TilesWrapper>
);

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            exportCommand,
            exportFarmgroupCommandTest,
        },
        dispatch,
    ),
});

export const FarmgroupsList = connect(null, mapDispatchToProps)(FarmgroupsListRaw);
